import Grid from '@components/core/layout/Grid';
import { Image } from '@components/core/Image';
import NextImage from 'next/image';
import Button from '@components/core/Button';

import { getInternalPath } from '@helpers/internalLink';

const getNavItemUrl = (navItem) => {
  if (!navItem.link) {
    return '';
  }

  return navItem?.link?.internalLink
    ? getInternalPath(navItem.link.internalLink)
    : navItem.link.externalUrl;
};

export default function HeaderSideImage(props: any) {
  const {
    heading,
    intro,
    image = null,
    ctaButton1,
    ctaButton2,
    staticSrc, // Optional only used by 404 page
    alt, // Optional only used by 404 page
  } = props;

  return (
    <>
      <Grid className='pt-4 xl:pt-16 pb-8 md:pb-12 xl:pb-24'>
        <div className='xl:hidden col-start-1 col-span-12 relative'>
          <div className='responsive aspect-[720/405]'>
            {
              staticSrc ?
                <NextImage
                  src={ staticSrc }
                  fill={ true }
                  priority={ true }
                  alt={ alt }
                  style={ { objectFit: 'contain' } } />
                :
                <Image
                  sanityImage={ image.image }
                  layout='fill'
                  objectFit='contain'
                  priority={ true }
                  sizes='640px'
                />
            }
          </div>
        </div>
        <h1 className='col-start-1 col-span-12 xl:col-span-6 mt-6 xl:mt-0 mb-2 md:mb-4 text-heading-lg md:text-heading-xl xl:text-heading-2xl'>
          { heading.split('\n').map((str, index) => <p key={ index }>{ str }</p> ) }
        </h1>
        <div className='hidden col-start-1 col-span-12 xl:block xl:col-start-7 xl:col-span-6 xl:row-span-3 relative'>
          <div className='relative w-full h-full'>
            {
              staticSrc ?
                <NextImage
                  src={ staticSrc }
                  fill={ true }
                  priority={ true }
                  alt={ alt }
                  style={ { objectFit: 'contain' } } />
                :
                <Image
                  sanityImage={ image.image }
                  layout='fill'
                  objectFit='contain'
                  priority={ true }
                  sizes='600px'
                />
            }
          </div>
        </div>
        <div className='col-start-1 col-span-12 xl:col-span-5 text-body-xl'>{ intro }</div>
        <div className='col-start-1 col-span-12 xl:col-end-6'>
          <div className='flex flex-col md:flex-row items-start gap-4 mt-4 md:mt-6 xl:mt-8'>
            { ctaButton1 &&
                <Button href={ getNavItemUrl(ctaButton1) } type='solid' className='whitespace-nowrap' externalUrl={ ctaButton1?.link?.externalUrl }>
                  { ctaButton1.label }
                </Button>
            }
            { ctaButton2 &&
                <Button href={ getNavItemUrl(ctaButton2) } type='ghost' className='whitespace-nowrap' externalUrl={ ctaButton2?.link?.externalUrl }>
                  { ctaButton2.label }
                </Button>
            }
          </div>
        </div>
      </Grid>
    </>
  );
}
