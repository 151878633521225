import Grid from '@components/core/layout/Grid';

export default function ContactSection(props) {

  const styling = {
    columnTitle: 'text-heading-xs md:text-heading-sm mb-2 md:mb-4',
    columnKey: 'text-body-md md:text-body-lg table-cell pb-2 h-8',
    columnValue: `
      text-body-md text-body-md--semibold md:text-body-lg md:text-body-lg--semibold table-cell pb-2
      xl:pl-8 md:pl-6 pl-4
    `,
  };

  const renderTime = (day) => (
    <div className={ `${ styling.columnValue }` }>
      { day?.blockOne?.from && day.blockOne.from.length > 0 ? day.blockOne.from : '* ' } - { day?.blockOne?.to ?? '' }
      {
        day?.blockTwo?.to &&
        day?.blockTwo?.to.length > 0 &&
      ` / ${ day?.blockTwo?.from.length > 0
        ? day.blockTwo?.from
        : '*' } - ${ day?.blockTwo?.to ?? '' }`
      }
    </div>
  );

  return (
    <Grid>
      <div className='col-span-12'>
        <h2 className='text-heading-md md:text-heading-lg mb-6 md:mb-8 xl:12'>Contactgegevens</h2>
      </div>
      <div className='col-span-12 md:col-span-6 xl:col-span-4 mb-8 xl:mb-0'>
        <div className={ `${ styling.columnTitle }` }>Locatie</div>
        <div className='table self-start'>
          <div className='table-row-group'>
            <div className='table-row'>
              <div className={ `${ styling.columnKey } w-[163.5px] md:w-[116px] xl:w-[75px]` }>Naam</div>
              <div className={ `${ styling.columnValue }` }>{ props.name }</div>
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Adres</div>
              <div className={ `${ styling.columnValue }` }>{ props.address }</div>
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Postcode</div>
              <div className={ `${ styling.columnValue }` }>{ props.zipCode }</div>
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Stad</div>
              <div className={ `${ styling.columnValue }` }>{ props.city }</div>
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Postbus</div>
              <div className={ `${ styling.columnValue } whitespace-pre-line` }>{ props.postBox }</div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-span-12 md:col-span-6 xl:col-span-4  mb-8 xl:mb-0'>
        <div className={ `${ styling.columnTitle }` }>Contact</div>
        <div className='tatable self-start'>
          <div className='table-row-group'>
            <div className='table-row'>
              <div className={ `${ styling.columnKey } w-[163.5px] md:w-[145px]` }>Telefoon</div>
              <a href={ `tel:${ props.phoneNumber }` } className={ `${ styling.columnValue } text-primary-500` }>{ props.phoneNumber }</a>
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Mailadres</div>
              <a href={ `mailto: ${ props.email }` }  className={ `${ styling.columnValue } text-primary-500` }>{ props.email }</a>
            </div>
            {
              props.lRKNumberKDV &&
              <div className='table-row' >
                <div className={ `${ styling.columnKey }` }>LRK-nummer KOV</div>
                <a
                  className={ `${ styling.columnValue } text-primary` }
                  href={ props.lRKLink }
                  target='__blank'>
                  { props.lRKNumberKDV }
                </a>
              </div>
            }
            {
              props.lRKNumberGOB &&
              <div className='table-row' >
                <div className={ `${ styling.columnKey }` }>LRK-nummer GOB</div>
                <a
                  className={ `${ styling.columnValue } text-primary` }
                  href={ props.lRKLink }
                  target='__blank'>
                  { props.lRKNumberGOB }
                </a>
              </div>
            }
            {
              props.lRKNumberBSO &&
              <div className='table-row'>
                <div className={ `${ styling.columnKey }` }>LRK-nummer BSO</div>
                <a
                  className={ `${ styling.columnValue } text-primary` }
                  href={ props.lRKLink }
                  target='__blank'>
                  { props.lRKNumberBSO }
                </a>
              </div>
            }
            {
              props.lRKNumberPOV &&
              <div className='table-row'>
                <div className={ `${ styling.columnKey }` }>LRK-nummer POV</div>
                <a
                  className={ `${ styling.columnValue } text-primary` }
                  href={ props.lRKLink }
                  target='__blank'>
                  { props.lRKNumberPOV }
                </a>
              </div>
            }
            {
              props.lRKNumberVGO &&
              <div className='table-row'>
                <div className={ `${ styling.columnKey }` }>LRK-nummer VGO</div>
                <a
                  className={ `${ styling.columnValue } text-primary` }
                  href={ props.lRKLink }
                  target='__blank'>
                  { props.lRKNumberVGO }
                </a>
              </div>
            }
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>KVK-nummer</div>
              <div className={ `${ styling.columnValue }` }>{ props.kVKNumber ?? '--'}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-span-12 md:col-span-6 xl:col-span-4 mb-6 md:mb-8 xl:mb-12'>
        <div className={ `${ styling.columnTitle }` }>Openingstijden</div>
        <div className='table self-start'>
          <div className='table-row-group'>
            <div className='table-row'>
              <div className={ `${ styling.columnKey } xl:w-[75px] md:w-[116px] w-[163.5px]` }>Maandag</div>
              { renderTime(props.weeklySchedule?.monday) }
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Dinsdag</div>
              { renderTime(props.weeklySchedule?.tuesday) }
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Woensdag</div>
              { renderTime(props.weeklySchedule?.wednesday) }
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Donderdag</div>
              { renderTime(props.weeklySchedule?.thursday) }
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Vrijdag</div>
              { renderTime(props.weeklySchedule?.friday) }
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}
