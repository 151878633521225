import { getClient } from '@lib/sanity';

export async function fetchSingleLocationProducts(lovale, preview = false, slug) {
  return await getClient(preview).fetch(`
    *[_type == 'page' && slug.current =='${ slug }'][0]{
      sectionBox[@._type == 'multiContactSection'] {
        products[] {
          productName
        }
      }
    }.sectionBox[0].products
  `);
}

export async function fetchKinderopvangLocationsNames(locale, preview = false) {

  const kinderopvangOverviewID = await getClient(preview).fetch(`
    *[_type == 'page' && slug.current == 'kinderopvang'][0]{
      _id,
      'locationsPageID': *[_type == 'page' && slug.current == 'locaties' && parent._ref == ^._id]._id
    }.locationsPageID
  `);

  return await getClient(preview).fetch(`
    *[ _type == "page" && !(_id in path("drafts.**")) && parentSlug == "locaties" && __i18n_lang == "${ locale }" && level == 3 && parent._ref == "${ kinderopvangOverviewID.join() }"] {
      pageTitle,
      locationCode,
      'products': sectionBox[@._type == 'multiContactSection'] {
        products[] {
          productName,
          'city': contactDetails {
            city
          }.city
        }
      }.products[]
    }
  `);
}

export async function fetchKinderopvangLocations(locale, preview = false) {

  const kinderopvangOverviewID = await getClient(preview).fetch(`
    *[_type == 'page' && slug.current == 'kinderopvang'][0]{
      _id,
      'locationsPageID': *[_type == 'page' && slug.current == 'locaties' && parent._ref == ^._id]._id
    }.locationsPageID
  `);

  return await getClient(preview).fetch(`
    *[ _type == "page" && !(_id in path("drafts.**")) && parentSlug == "locaties" && __i18n_lang == "${ locale }" && level == 3 && parent._ref == "${ kinderopvangOverviewID.join() }"] {
      _id,
      pageTitle,
      slug,
      geoLocation {
        lat,
        lng
      },
      locationCode,
      cardImage,
      __i18n_lang,
      sectionBox[@._type == 'multiContactSection'] {
        ...
      }
    }
  `);
}

export async function fetchLocations(locale, preview = false, parentID) {
  return await getClient(preview).fetch(`
    *[ _type == "page" && !(_id in path("drafts.**")) && parentSlug == "locaties" && __i18n_lang == "${ locale }" && level == 3 && parent._ref == "${ parentID }"] {
      _id,
      pageTitle,
      slug,
      geoLocation {
        lat,
        lng
      },
      locationCode,
      cardImage,
      __i18n_lang,
      sectionBox[@._type == 'multiContactSection'] {
        ...
      }
    } | order(lower(pageTitle) asc)
  `);
}
