/* Modifiers */
export function slugify(slug) {
  const replacement = '-';

  return slug.replace(/[^A-Za-z0-9\-\s]/g, '').trim().replace(/\s+/g, replacement).toLowerCase();
}

export function capitalizeFirstChar(str: string) {
  if (typeof str !== 'string' || !str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function kebabCase(str: string) {
  if (typeof str !== 'string' || !str) return str;
  return str.toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
}

/* Validators */
export function isEmail(emailString: any) {
  if (typeof emailString !== 'string' || !emailString) return false;

  const testPattern = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  return testPattern.test(emailString);
}

export function isPhoneNumber(phoneString: any) {
  if (typeof phoneString !== 'string' || !phoneString) return false;

  const testPattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

  return testPattern.test(phoneString);
}

export function notEmpty(string: any) {
  if (typeof string !== 'string' || !string) return false;

  const value = string.trim();

  return (!!value && value.length > 0);
}

export function isDutchZipcode(string: any) {
  if (string.length === 0) return true;
  const testPattern = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;

  return testPattern.test(string);
}
