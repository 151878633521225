import { useState, useEffect, useRef, LegacyRef } from 'react';
import Link from 'next/link';
import Dropdown, { TDropdownOption, TDropdownOptions } from '@core/Dropdown';
import { slugify } from '@helpers/string';

const styling = {
  title: sq`
    text-heading-xs
    mb-2

    flex-shrink-0
    mr-4

    lg:text-heading-sm
    lg:mb-0

    xl:mb-4
  `,
  navHolder: sq`
    sticky
    z-10
    top-16

    self-start
    col-span-12
    px-4
    -mx-4
    py-6
    -mb-8
    -mt-8
    bg-white
    pointer-events-auto

    md:px-6
    md:-mx-6
    md:-mt-16

    lg:-mb-16
    lg:py-8
    lg:top-[104px]
    lg:px-12
    lg:-mx-12

    xl:bg-transparent
    xl:p-0
    xl:m-0
    xl:col-span-4
    xl:top-[137px]
    xl:bottom-0
  `,
  nav: sq`
    flex
    flex-col
    items-start
    p-4
    rounded-[1.5rem]
    bg-shade-50

    lg:flex-row
    lg:items-center

    xl:items-start
    xl:flex-col

    xl:p-8
    xl:rounded-2xl
  `,
  navMobile: sq`
    flex
    flex-col
    items-start
    lg:flex-row
    lg:items-center
    col-span-12
    self-start
    bg-shade-50
    p-4
    rounded-[1.5rem]
  `,
  link: sq`
    text-body-lg
    text-shade-900
  `,
  linkActive: sq`
    !text-primary
    text-body-lg--semibold
  `,
};

export default function AnchorNav(props) {
  const navElement = useRef<HTMLDivElement>();

  const [ activeBlockId, setActiveBlockId ] = useState<string>();
  const [ activeBlockDropdown, setActiveBlockDropdown ] = useState<TDropdownOption>();

  const headings: Array<{ title: string, id: string }> = props.headings;
  const dropdownOptions: TDropdownOptions = props.headings.map(heading => {
    return {
      value: slugify(heading.title),
      label: heading.title,
    };
  });

  // Run once
  useEffect(() => {
    const allBlocks: HTMLElement[] = Array.from(props.sectionRef.current.querySelectorAll('[data-js-anchor-id]'));
    const scrollHandler = () => {
      let currentActiveBlock;

      if (window.location.hash) {
        setActiveBlockDropdown(dropdownOptions.find((option) => option?.label === window.location.hash));
      }

      for (let blockIndex = allBlocks.length - 1; blockIndex > -1; blockIndex--) {
        const block = allBlocks[blockIndex];
        const blockPosition = block.getBoundingClientRect();

        if ((window.scrollY + window.innerHeight * 0.3) > blockPosition.top + window.scrollY) {
          currentActiveBlock = block.dataset.jsAnchorId;
          break;
        }
      }
      setActiveBlockId(currentActiveBlock);
    };

    window.addEventListener('scroll', scrollHandler);

    scrollHandler();

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [ dropdownOptions, props.sectionRef ]);

  return (
    <>
      {/* Spacer for mobile nav */}
      <div className={ styling.navHolder }>
        <nav
          className={ `${ styling.nav } ${ 'bg-[inherit]' }` }
          ref={ navElement as LegacyRef<HTMLElement> | undefined }
        >
          <p className={ styling.title }>Navigeer snel naar:</p>
          {/* Desktop list */}
          <div className='hidden gap-3 xl:grid'>
            { headings?.map((heading, headingIndex) => {
              return (
                <Link key={ headingIndex } href={ `#${ heading.id }` } legacyBehavior>
                  <a className={ `${ styling.link } ${ activeBlockId === heading.id ? styling.linkActive : '' }` }>{ heading.title }</a>
                </Link>
              );
            }) }
          </div>

          {/* Mobile dropdown */}
          <div className='relative w-full xl:hidden'>
            <Dropdown
              id='anchor-nav'
              options={ dropdownOptions }
              onChange={ (option) => {
                window.location.hash = option.value;
                setActiveBlockDropdown(option);
              } }
              value={ activeBlockDropdown }
              placeholder={ 'Maak een keuze...' }
            />
          </div>
        </nav>
      </div>
    </>
  );
}
