import React, { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import Grid from '@components/core/layout/Grid';
import Filters from '@components/Filters';

// Part before LookUp are keys and most be one of the domains
const lookUps = {
  kinderopvang: {
    BSO: 'Buitenschoolse opvang',
    POV: 'Peuteropvang',
    KDV: 'Kinderdagverblijf',
    ZJK: 'Zorg Jonge Kind',
  },
};

export default function KOVFilterSection() {
  const [ selectedProductType, setProductType ] = useState(null);
  const [ selectedLocation, setLocation ] = useState({
    value: '',
    isValid: true,
    validState: { }, // key: type of validation, value: boolean
    showErrorMessage: false,
  });
  const [ buttonIsDisabled, setButtonIsDisabled ] = useState(true);

  const router = useRouter();

  const handleEmitLocation = (e) => {
    setLocation({
      value: e.target.value ,
      isValid: true,
      validState: { }, // key: type of validation, value: boolean
      showErrorMessage: false,
    });
    setButtonIsDisabled(false);
  };

  const applyFilter = useCallback(() => {
    // Convert to url friendly instead of options friendly
    const selectedProductValues = selectedProductType?.map( productType => productType.value).join();
    router.push({
      pathname: 'kinderopvang/locaties',
      query: {
        ...(selectedProductValues ? { productType: selectedProductValues } : {}),
        ...(selectedLocation?.value ? { location: selectedLocation.value } : {}),
      },
    }, undefined, { scroll: false });
  }, [ router, selectedProductType, selectedLocation ]);

  return (
    <Grid>
      <div className='col-span-12 md:col-span-8 md:col-start-3'>
        <h2 className='text-heading-md md:text-heading-lg text-center mb-4'>Vind een kinderopvang in jouw omgeving</h2>
        <div className='text-body-md md:text-body-lg text-center'>
          Vanuit onze landelijke stichting bieden we kinderopvang, peuteropvang en opvang op de BSO op meer dan 250 locaties in Nederland.
        </div>
      </div>
      <div className='col-span-12 md:col-span-10 md:col-start-2 p-4 bg-primary-50 mt-16 rounded-[30px] xl:rounded-[60px]'>
        <div className='flex flex-col xl:flex-row xl:whitespace-nowrap'>
          <Filters
            position='section'
            options={ {
              productTypes: lookUps.kinderopvang,
            } }
            selectedProductType={ selectedProductType }
            selectedLocation={ selectedLocation }
            buttonIsDisabled={ buttonIsDisabled }
            emit={ {
              productType: setProductType,
              location: handleEmitLocation,
              applyFilter,
              disableApplyButton: setButtonIsDisabled,
            } }
            buttonLabel='Bekijk locaties'
          />
        </div>
      </div>
    </Grid>
  );
}
