import { Image } from '@components/core/Image';
import Button from '@components/core/Button';
import Grid from '@components/core/layout/Grid';
import { getInternalPath } from '@helpers/internalLink';

export default function CtaSection(props) {
  const ctaOneLink = props.ctaOne?.link.internalLink ? getInternalPath(props.ctaOne.link.internalLink) : props.ctaOne?.link.externalUrl;
  const ctaTwoLink = props.ctaTwo?.link.internalLink ? getInternalPath(props.ctaTwo.link.internalLink) : props.ctaTwo?.link.externalUrl;

  const Bubbles = (props) => (
    <svg
      viewBox='0 0 592 448'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      { ...props }
    >
      <circle cx={ 40 } cy={ 40 } r={ 40 } className='fill-primary-50' />
      <circle cx={ 581 } cy={ 92 } r={ 60 } className='fill-primary-400' />
      <circle cx={ 480 } cy={ 432 } r={ 64 } className='fill-primary-100' />
      <circle cx={ 115 } cy={ 324 } r={ 44 } className='fill-primary-300' />
    </svg>
  );

  return (
    <Grid>
      <div className='bg-white rounded-[48px] col-span-12 flex flex-col xl:flex-row overflow-hidden'>
        <div className='p-8 md:p-16 xl:p-24 w-full xl:w-1/2'>
          <div className='text-heading-lg mb-4'>{ props.header }</div>
          <div className='text-body-lg md:text-body-xl mb-6 md:mb-8 xl:mb-12'>{ props.intro }</div>
          <div className='flex flex-col md:flex-row'>
            {
              props.ctaOne &&
              <Button
                href={ ctaOneLink }
                className='self-start mb-4 md:mr-4 md:mb-0 whitespace-nowrap'
                type='solid'
                externalUrl={ props.ctaOne?.link.externalUrl }>
                { props.ctaOne.label }
              </Button>
            }
            {
              props.ctaTwo &&
              <Button
                href={ ctaTwoLink }
                className='self-start whitespace-nowrap'
                type='ghost'
                externalUrl={ props.ctaTwo?.link.externalUrl }>
                { props.ctaTwo.label }
              </Button>
            }
          </div>
        </div>
        <div className='w-full xl:w-1/2 relative h-full flex justify-center items-center py-[29.34px] px-[63.18px] xl:py-[44px] xl:px-[112px] md:py-[61.58px] md:px-[132.63px]'>
          <div className='relative w-[216.63px] md:w-[454.74px] xl:w-[384px]'>
            <Image
              sanityImage={ props.ctaImage.image }
              className='rounded-full'
              layout='responsive'
              sizes='(min-width: 1280px) 350px, 400px'
            />
          </div>
          <Bubbles className='h-full w-full top-6 absolute z-10 left-0' />
        </div>
      </div>
    </Grid>
  );
}
