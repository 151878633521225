import * as Icons from '@mui/icons-material';

// Tailwind to CSS-in-JS
import tw from 'twin.macro';

export default function Icon({ name, className }: { name: keyof typeof Icons, className?: string }) {
  const IconComponent = Icons[name];
  return (
    <div className={ `flex w-6 h-6 ${ className }` }>
      <IconComponent
        sx={ tw`w-full h-full` }
      />
    </div>
  );
}
