import Grid from '@components/core/layout/Grid';
import Icon from '@components/core/Icon';
import Button from '@components/core/Button';
import { getInternalPath } from '@helpers/internalLink';

const Circle = (props) => (
  <svg
    width={ 24 }
    height={ 24 }
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    { ...props }
  >
    <circle cx={ 12 } cy={ 12 } r={ 12 } className='fill-primary-300' />
  </svg>
);

export default function UspSection(props) {
  const ctaLink = props.ctaButton?.link.internalLink ? getInternalPath(props.ctaButton?.link?.internalLink) : props.ctaButton?.link?.externalUrl;
  return (
    <Grid>
      <div className={ sq`
        text-heading-md md:text-heading-lg
        col-span-12 md:col-span-10 xl:col-span-8
        col-start-1 md:col-start-2 xl:col-start-3
        text-center
        mb-6 md:mb-8 xl:mb-12
      `
      }>{ props.header }</div>
      {
        props.columns && props.columns.length > 0 &&
        props.columns.map((column, index) => {
          return (
            <div className='mb-6 md:mb-0 col-span-12 md:col-span-4 flex flex-col items-center' key={ column._key }>
              <div className='bg-primary w-24 h-24 rounded-full relative mb-6'>
                <Circle className={ (index + 1) % 2 == 0 ? 'bottom-0 right-0 absolute': '' } />
                <Icon name={ column.icon } className='text-white !w-12 !h-12 absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]' />
              </div>
              <div className='text-center text-heading-xs md:text-heading-sm text-primary mb-2'>{ column.title }</div>
              <div className='text-center text-body-md md:text-body-lg'>{ column.text }</div>
            </div>
          );
        })
      }
      <div className={ sq`
        col-span-12 md:col-span-10 xl:col-span-6
        col-start-1 md:col-start-2 xl:col-start-4
        flex flex-col
      ` }>
        {
          props.ctaTitle &&
          <div className='text-center text-heading-sm md:text-heading-md mt-6 md:mt-8 xl:mt-12 mb-2'>
            { props.ctaTitle }
          </div>
        }
        {
          props.ctaDescription &&
          <div className='text-center text-body-md md:text-body-lg'>{ props.ctaDescription }</div>
        }
        {
          props.ctaButton &&
          <Button href={ ctaLink } className='self-center mt-4 md:mt-6'>{ props.ctaButton.label }</Button>
        }
      </div>
    </Grid>
  );
}
