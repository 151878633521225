import Grid from 'components/core/layout/Grid';
import Icon from '@components/core/Icon';

export default function DownloadSection(props) {

  return (
    <Grid>
      <div className='col-span-12 mb-4 md:mb-8 xl:mb-12'>
        <h2 className='text-heading-md md:text-heading-lg mb-2 md:mb-4'>
          { props.title }
        </h2>
        <div className='text-body-md md:text-body-lg'>
          { props.intro }
        </div>
      </div>
      { props.pdfFiles && props.pdfFiles.length > 0 &&

        props.pdfFiles.map((pdfFile) => {
          const name = pdfFile.fileName + '.pdf';
          return (
            <a
              href={ `${ pdfFile.asset.url }` }
              target='_blank'
              key={ pdfFile._key }
              className='col-span-12 md:col-span-6 xl:col-span-4 flex items-center py-6 text-primary border-b-[1px] border-primary-50' rel='noreferrer'
            >
              <Icon name='SaveAlt' className='mr-3' />
              <div className='text-body-lg'>{ name }</div>
            </a>
          );
        })
      }
    </Grid>
  );
}
