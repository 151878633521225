import RichText from '@components/core/RichText';
import Grid from 'components/core/layout/Grid';

export default function RichTextSection(props: any) {
  return (
    <Grid>
      <div className={ 'col-span-12 md:col-start-2 md:col-end-12  xl:col-start-3 xl:col-end-11' }>
        <RichText text={ props.richText?.text } />
      </div>
    </Grid>
  );
}
