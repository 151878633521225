import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import Grid from '@components/core/layout/Grid';

import { getInternalPath } from '@helpers/internalLink';

import Icon from './core/Icon';

const styling = {
  footer: sq`
    bg-primary-50
    bg-shade-900
    text-white
    relative
    rounded-t-[1rem]

    md:rounded-t-[1.5rem]
    xl:rounded-t-[2rem]

    after:hidden
    after:w-full
    after:h-full
    after:absolute
    after:top-0
    after:rounded-t-[1rem]
    after:bg-shade-800

    md:after:block
    md:after:w-[45%]
    md:after:h-full
    md:after:rounded-t-[1.5rem]
    xl:after:rounded-t-[2rem]

    transition-colors
    duration-300
  `,
  logo: sq`
    relative
    z-10
    flex
    flex-shrink-0
    relative
    w-[185px]
    h-8
    my-8
    mr-auto
    overflow-hidden

    md:my-auto
    xl:w-[232px]
    xl:h-10
    xl:mr-3
    2xl:mr-6
  `,
  footerColumn: sq`
    relative
    z-10
    col-span-12
    mb-8
    px-4

    last:pr-0

    md:first:pl-0
    md:mb-12
    md:col-span-6
  `,
  footerAnchor: sq`
    py-1
    underline-offset-1
    text-white
    text-body-md

    hover:text-primary
    hover:underline
    focus:text-primary
    focus:underline

    md:text-body-lg
    xl:py-0
  `,
};

export default function Footer(props: { theme: any; footerColumns: any[]; termsAndConditions: any; privacyStatement: any, className: string, disclaimerCookies: any, isoCertification: any }) {

  const disclaimerCookiesPath = props.disclaimerCookies.externalUrl ?? getInternalPath(props.disclaimerCookies.internalLink);

  const getNavItemUrl = (navItem) => {
    if (!navItem.link) {
      return '';
    }

    return navItem.link.internalLink
      ? getInternalPath(navItem.link.internalLink)
      : navItem.link.externalUrl;
  };

  const isoCertificationPath = props.isoCertification.externalUrl ?? getInternalPath(props.isoCertification.internalLink);

  const renderColumn = (column) => {
    return (
      <div className={ styling.footerColumn } key={ column._key }>
        <h3 className='text-heading-xs pb-4'>{ column.title }</h3>
        <ul>
          { column.columnItems && column.columnItems.map(columnItem => renderListItem(columnItem)) }
        </ul>
      </div>
    );
  };

  // Render sub nav item (link or button)
  const renderListItem = (columnItem) => {
    const columnItemUrl = getNavItemUrl(columnItem);
    return (
      <li className='pb-4' key={ columnItem._key }>
        <Link
          href={ columnItemUrl }
          legacyBehavior>
          <a className={ styling.footerAnchor } target={ columnItem.link?.externalUrl ? '_blank' : '_self' }>
            { columnItem.label }
          </a>
        </Link>
      </li>
    );
  };

  return (
    <footer className={ props.className }>
      <div className={ styling.footer }>
        <Grid>
          <div className='col-span-6 md:py-12 xl:py-16'>
            <Link href='/' className={ styling.logo }>

              <Image
                src={ `/assets/logos/${ props.theme }-white.svg` }
                alt='Logo Meander-Prokino'
                width={ 232 }
                height={ 40 }
                className='object-contain object-left'
                sizes='232px'
                unoptimized={ true }
              />

            </Link>
          </div>
          <div className='col-span-1 col-end-13 self-center justify-self-end'>
            <Image
              src={ `/assets/${ props.theme }-bolletjes.svg` }
              alt='Meander prokino circles'
              width={ 41 }
              height={ 90 }
              className='object-contain object-left'
              unoptimized={ true }
            />
          </div>
        </Grid>

        {/* Desktop && mobile */}
        <Grid className='px-0 md-px-4 grid md:hidden xl:grid'>
          <div className='col-span-12 md:col-span-6'>
            <div className='grid grid-cols-12'>
              { props.footerColumns.slice(0, 2).map((column) => renderColumn(column)) }
            </div>
          </div>
          <div className='col-span-12 md:col-span-6 rounded-t-[1rem] pt-8 md:pt-0 bg-shade-800 md:bg-shade-900 relative z-10'>
            <div className='grid grid-cols-12'>
              { props.footerColumns.slice(2, 4).map((column) => renderColumn(column)) }
            </div>
          </div>
          <div className='px-4 col-span-12 z-10 text-left md:text-center text-body-sm md:text-body-md text-shade-400 pb-8 bg-shade-800 md:bg-transparent'>
            <span>Alle rechten voorbehouden Stichting Meander-Prokino | </span>
            <br className='block md:hidden' />
            <Link href={ disclaimerCookiesPath } className='underline'>
              Disclaimer & Cookies
            </Link>
            <span> | </span>
            <a
              href={ isoCertificationPath }
              target={ props.isoCertification.externalUrl ? '_blank' : '_self' }
              className='underline' rel='noreferrer'>
                ISO 9001:2015
            </a>
            <span> | </span>
            <Link href='https://www.facebook.com/prokino.kinderopvang' className='ml-0' target='_blank' aria-label='Open Facebook'>
              <Icon name='Facebook' className='!inline-block' />
            </Link>
            <Link href='https://instagram.com/prokinokinderopvang' className='ml-2' target='_blank' aria-label='Open Instagram'>
              <Icon name='Instagram' className='!inline-block' />
            </Link>
            <Link href='https://www.linkedin.com/company/stichting-prokino-kinderopvang' className='ml-2' target='_blank' aria-label='Open LinkedIn'>
              <Icon name='LinkedIn' className='!inline-block' />
            </Link>
          </div>
        </Grid>

        {/* Tablet */}
        <Grid className='px-0 md-px-4 hidden md:grid xl:hidden'>
          <div className='col-span-6'>
            { props.footerColumns.slice(0, 1).map((column) => renderColumn(column)) }
          </div>
          <div className='col-span-6'>
            { props.footerColumns.slice(1, 2).map((column) => renderColumn(column)) }
          </div>
          <div className='col-span-6'>
            { props.footerColumns.slice(2, 3).map((column) => renderColumn(column)) }
          </div>
          <div className='col-span-6'>
            { props.footerColumns.slice(3, 4).map((column) => renderColumn(column)) }
          </div>
          <div className='px-4 col-span-12 z-10 text-left md:text-center text-body-sm md:text-body-md text-shade-400 pb-8 bg-shade-800 md:bg-transparent'>
            <span>Alle rechten voorbehouden Stichting Meander-Prokino | </span>
            <br className='block md:hidden' />
            <Link href={ disclaimerCookiesPath } className='underline'>
              Disclaimer & Cookies
            </Link>
            <span> | </span>
            <a
              href={ isoCertificationPath }
              target={ props.isoCertification.externalUrl ? '_blank' : '_self' }
              className='underline' rel='noreferrer'>
                ISO 9001:2015
            </a>
            <span> | </span>
            <Link href='https://www.facebook.com/prokino.kinderopvang' className='ml-0' target='_blank' aria-label='Open Facebook'>
              <Icon name='Facebook' className='!inline-block' />
            </Link>
            <Link href='https://instagram.com/prokinokinderopvang' className='ml-2' target='_blank' aria-label='Open Instagram'>
              <Icon name='Instagram' className='!inline-block' />
            </Link>
            <Link href='https://www.linkedin.com/company/stichting-prokino-kinderopvang' className='ml-2' target='_blank' aria-label='Open LinkedIn'>
              <Icon name='LinkedIn' className='!inline-block' />
            </Link>
          </div>
        </Grid>

        <div className='h-2 relative z-20 md:h-4 transition-all duration-300'>
          <Image
            src={ `/assets/domain-bars/${ props.theme }.svg` }
            alt='Decorative bar'
            fill={ true }
            sizes='(min-width: 1280px) 1440px, (min-width: 768px) 750px, 640px'
            unoptimized={ true }
          />
        </div>
      </div>
    </footer>
  );
}
