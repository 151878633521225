import { useState } from 'react';
import { Image } from '@components/core/Image';
import ReactPlayer from 'react-player/lazy';
import Grid from '@core/layout/Grid';
import useMediaQuery from '@helpers/hooks/useMediaQuery';

interface IVideo {
  url: string,
  image: any,
  alt: string,
  description?: string,
}

const styling = {
  container: `
    col-span-12
    md:col-span-10
    md:col-start-2
    xl:col-span-8
    xl:col-start-3
  `,
  videoWrapper: `
    relative
    inline-flex
    w-full
    rounded-2xl
    overflow-hidden
    aspect-video
    mb-3
    md:mb-4
  `,
  videoOverlay: `
    group
    absolute
    top-0
    left-0
    flex
    items-center
    justify-center
    w-full
    h-full
    bg-red
    cursor-pointer
    bg-[rgba(0,0,0,0.4)]
  `,
  videoOverlayBackground: `
    absolute
    top-0
    left-0
    w-full
    h-full
    object-cover
  `,
  playButton: {
    icon: `
      absolute
      w-[14.67px]
      h-[18.67px]
      top-[22.67px]
      left-[26.67px]
      right-[26.67px]
      bottom-[22.67px]
      md:top-[28.33px]
      md:left-[33.33px]
      md:right-[28.33px]
      md:bottom-[28.33px]
      md:w-[18.33px]
      md:h-[23.33px]
    `,
    container: `
      relative
      group-hover:scale-110
      transition-transform
      bg-primary-50
      w-16
      h-16
      md:w-20
      md:h-20
      rounded-full
      text-primary-500
      flex
      justify-center
    `,
  },
  description: `
    col-span-12
    text-[0.875rem]
    font-light
    leading-[1.65]
    text-grey-shade-500
  `,
};

export default function Video({
  url,
  image: { image },
  description,
}: IVideo) {

  const [ playing, setPlaying ] = useState(false);
  const isMobile = useMediaQuery(786);

  const SvgComponentL = (props) => (
    <svg
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      { ...props }
    >
      <path d='M.333 24.166V.833L18.666 12.5.333 24.166Z' />
    </svg>
  );

  const SvgComponentS = (props) => (
    <svg
      width={ 15 }
      height={ 19 }
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      { ...props }
    >
      <path d='M.167 18.834V.167L14.834 9.5.167 18.834Z' fill='currentColor' />
    </svg>
  );

  return (
    <Grid className='overflow-hidden'>
      <div className={ styling.container }>
        <div className={ styling.videoWrapper }>
          { playing
            ? (
              <ReactPlayer
                url={ url }
                playing={ playing }
                width='100%'
                height='auto'
                style={ { aspectRatio: '16/9' } }
                controls={ true }
              />
            )
            : (
              <div className={ styling.videoOverlay } onClick={ () => setPlaying(true) }>
                <div className={ styling.videoOverlayBackground }>
                  <Image
                    sanityImage={ image }
                    layout='fill'
                    objectFit='cover'
                    sizes='(min-width: 1280px) 650px, (min-width: 768px) 850px, 400px'
                  />
                </div>
                <div className={ styling.playButton.container }>
                  {
                    isMobile ?
                      <SvgComponentS className={ styling.playButton.icon } /> :
                      <SvgComponentL className={ styling.playButton.icon } />
                  }
                </div>
              </div>
            )
          }
        </div>
        { description &&
            <p className={ styling.description }>{ description }</p>
        }
      </div>
    </Grid>
  );
}
