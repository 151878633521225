import { Image } from '@components/core/Image';
import Grid from '@core/layout/Grid';

interface IImageSection {
  image: any,
  description?: string,
  className: boolean,
  sectionFullWidth: boolean
}

const styling = {
  container: sq`
    col-span-12
    md:col-span-10
    md:col-start-2
    xl:col-span-8
    xl:col-start-3
  `,
  imageWrapper: 'mb-2',
  description: sq`
    col-span-12
    md:col-span-10
    md:col-start-2
    xl:col-span-8
    xl:col-start-3
    text-body-sm
    md:text-body-md
    text-shade-700
  `,
};

export default function ImageSection(props: IImageSection) {

  const {
    image: { image },
    description,
  } = props;

  return (
    <>
      {
        props.sectionFullWidth ?
          <div style={ { position: 'relative' } }>
            <Image
              sanityImage={ image }
              layout='responsive'
              sizes='(min-width: 1280px) 1440px, (min-width: 768px) 750px, 640px'
            />
          </div>
          :
          <Grid>
            <div className={ styling.container }>
              <div className={ styling.imageWrapper }>
                <Image
                  sanityImage={ image }
                  layout='responsive'
                  className='rounded-[32px]'
                  sizes='(min-width: 1280px) 850px, (min-width: 768px) 750px, 640px'
                />
              </div>
              { description &&
            <p className={ styling.description }>{ description }</p>
              }
            </div>
          </Grid>
      }
    </>
  );
}
