export interface IgetInternalPath {
  slug: any,
  __i18n_lang: 'nl',
  parent: any,
}

export function getInternalPath(props: IgetInternalPath) {
  const { slug } = props;
  const lastSlug = slug.current;
  const path = [ ];

  // // Is home page
  if (lastSlug === '/') {
    return slug.current;
  } else {
    path.unshift(slug.current);
  }

  // While has parent slug at the slug
  let currentElement = props;
  while (currentElement.parent && currentElement.parent.slug.current !== '/') {
    path.unshift(currentElement.parent.slug.current);
    currentElement = currentElement.parent;
  }

  return '/' + path.join('/');
}
