import Grid from '@components/core/layout/Grid';
import { Image } from '@components/core/Image';

export default function QuoteSection(props) {
  return (
    <Grid>
      <div className={ sq`
        col-span-12
        md:col-span-10
        xl:col-span-8
        col-start-1
        md:col-start-2
        xl:col-start-3
        ${ props.sectionBackgroundColor ? 'bg-white': 'bg-primary-50' }
        rounded-2xl
        md:rounded-3xl
        xl:rounded-[32px]
        p-4
        md:p-6
        xl:p-8
      ` }>
        <q className='text-heading-sm md:text-heading-md'>{ props.quote }</q>
        <div className='flex mt-4 md:mt-6 xl:mt-8'>
          <div className='rounded-full overflow-hidden min-w-[3.5rem] min-h-[3.5rem] max-h-[3.5rem] max-w-[3.5rem] relative'>
            <Image
              sanityImage={ props.profileImage.image }
              layout='fill'
              objectFit='cover'
              sizes='56px'
            />
          </div>
          <div className='flex flex-col ml-2 self-center'>
            <div className='text-body-sm md:text-body-md text-body-sm--semibold md:text-body-md--semibold'>{ props.fullName }</div>
            <div className='text-body-sm md:text-body-md'>{ props.role }</div>
          </div>
        </div>
      </div>
    </Grid>
  );
}
