import { useEffect, useState } from 'react';

const useMediaQuery = (minWidth: number) => {
  const [ state, setState ] = useState({
    windowWidth: undefined as unknown as number,
    isDesiredWidth: false,
  });

  useEffect(() => {
    const resizeHandler = () => {
      const currentWindowWidth = window.innerWidth;
      const isDesiredWidth = currentWindowWidth < minWidth;
      setState({ windowWidth: currentWindowWidth, isDesiredWidth });
    };
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
    return () => window.removeEventListener('resize', resizeHandler);
  }, [ minWidth, state.windowWidth ]);

  return state.isDesiredWidth;
};

export default useMediaQuery;
