import { ChangeEvent, useCallback, useState } from 'react';
import Link from 'next/link';
import ReCAPTCHA from 'react-google-recaptcha';

import { getInternalPath, IgetInternalPath } from '@helpers/internalLink';

import Button from '@components/core/Button';
import Input from '@components/core/Input';
import Grid from '@components/core/layout/Grid';
import Icon from '@components/core/Icon';

import { notEmpty, isEmail, isPhoneNumber } from '@helpers/string';

const styling = {
  errorMessage: `
    text-body-sm
    text-left
    text-error
  `,
  checkbox: `
    peer
    block
    appearance-none
    cursor-pointer
    rounded-sm
    w-5
    h-5
    min-w-5
    border
    border-shade-300

    checked:bg-primary
    checked:border-primary
    hover:border-primary-300
    hover:bg-primary-50
  `,
};

interface IContactForm {
  title: string,
  intro: string,
  privacyLink: {
    externalUrl?: string,
    internalLink?: IgetInternalPath,
  },
  termsLink: {
    externalUrl?: string,
    internalLink?: IgetInternalPath,
  },
  emailSubject: string,
  recipientEmail: string,
  submitButtonLabel: string,
  locale: string,
  responseBody: string,
  responseTitle: string
}

export default function ContactForm({
  title,
  intro,
  privacyLink,
  termsLink,
  emailSubject,
  recipientEmail,
  submitButtonLabel,
  responseBody,
  responseTitle,
}: IContactForm) {

  const defaultFieldState = {
    value: '',
    isValid: false,
    validState: {}, // key: type of validation, value: boolean
    showErrorMessage: false,
  };

  const [ formState, setFormState ] = useState<{[key: string]: any}>({
    firstName: { ...defaultFieldState },
    preposition: { ...defaultFieldState },
    lastName: { ...defaultFieldState },
    email: { ...defaultFieldState },
    phone: { ...defaultFieldState },
    openQuestion: { ...defaultFieldState },
    termsAccepted: {
      value: '',
      isValid: false,
      showErrorMessage: false,
    },
    recaptcha: {
      value: '',
      isValid: false,
      showErrorMessage: false,
    },
  });

  const [ loading, setLoading ] = useState(false);
  const [ submitted, setSubmitted ] = useState(false);
  const [ formErrorMessage, setFormErrorMessage ] = useState('');

  const termsLinkHref = termsLink.externalUrl ?? getInternalPath(termsLink.internalLink);
  const privacyLinkHref = privacyLink.externalUrl ?? getInternalPath(privacyLink.internalLink);

  const submitContactForm = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    const formElement = (e.target as HTMLFormElement);

    if (loading) {
      return;
    }

    let errorFound = false;

    for (const fieldName in formState) {
      if (!formState[fieldName].isValid) {
        setFormState((prevState: typeof formState) => {
          const nextState = Object.assign({}, prevState);

          nextState[fieldName].showErrorMessage = true;

          return nextState;
        });

        errorFound = true;
      }
    }

    if (errorFound) {
      setTimeout(() => {
        const errorElement: HTMLElement|null = formElement.querySelector('[data-error="true"]');

        if (errorElement) {
          errorElement.focus();
        }
      }, 50);

      return;
    }

    const data = {
      firstName: formState.firstName.value,
      lastName: formState.lastName.value,
      preposition: formState.preposition.value,
      openQuestion: formState.openQuestion.value,
      email: formState.email.value,
      phone: formState.phone.value,
      recipientEmail: recipientEmail,
      emailSubject: emailSubject,
      formLocation: window.location.href,
      recaptcha: formState.recaptcha.value,
    };

    setLoading(true);
    setFormErrorMessage('');

    fetch('/api/contact-form-email', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
      // Response received
        setLoading(false);

        if (res.status === 200 || res.status === 201) {
        // Response succeeded!
          setSubmitted(true);
          return;
        }

        return res.text();
      })
      .then(errorMessage => {
        setFormErrorMessage('Fout tijdens het verzenden. Probeer het opnieuw!');
        console.error(errorMessage);
      })
      .catch(error => {
        setFormErrorMessage('Fout tijdens het verzenden. Probeer het opnieuw!');
        console.error(error);
      });

  }, [ loading, formState, recipientEmail, emailSubject ]);

  const updateFormState = (fieldName: keyof typeof formState, key: 'value'|'isValid'|'validState'|'showErrorMessage', value: any) => {
    setFormState((prevState: typeof formState) => {
      const nextState = Object.assign({}, prevState);

      // Allow state to be changed for all keys
      nextState[fieldName][key] = value;

      // Clear error message after changing value
      if (key === 'value') {
        nextState[fieldName]['errorMessage'] = '';
      }

      return nextState;
    });
  };

  const handleEmitOnChange = (e: ChangeEvent, fieldName: keyof typeof formState) => {
    updateFormState(fieldName, 'value', (e.target as HTMLInputElement).value);
    updateFormState(fieldName, 'showErrorMessage', false);
  };

  const handleEmitValidate = (validState: { [key: string]: boolean }, fieldName: keyof typeof formState) => {
    let isValid = true;
    for (const validateKey in validState) {
      if (!validState[validateKey]) {
        isValid = false;
        break;
      }
    }
    updateFormState(fieldName, 'isValid', isValid);
    updateFormState(fieldName, 'validState', validState);
  };

  return (
    <Grid>
      <div className='col-span-12 md:col-span-8 md:col-start-3'>
        {
          submitted && !loading &&
          <div id='contact-formulier-succes'>
            <h2 className='text-heading-md md:text-heading-lg mb-4'>{ responseTitle }</h2>
            <div className='text-body-lg md:text-body-xl mb-6 md:mb-8 xl:mb-12'>{ responseBody }</div>
          </div>
        }
        {
          !submitted &&
          <div>
            <h2 className='text-heading-md md:text-heading-lg mb-4'>{ title }</h2>
            <div className='text-body-lg md:text-body-xl mb-6 md:mb-8 xl:mb-12'>{ intro }</div>
          </div>
        }
        { !submitted && (
          <form onSubmit={ submitContactForm } className={ loading ? 'opacity-50 pointer-events-none' : '' } id='contact-formulier'>
            <div className='flex flex-col gap-y-4'>
              <Input
                label='Voornaam'
                required
                value={ formState.firstName.value }
                isValid={ formState.firstName.isValid }
                showErrorMessage={ formState.firstName.showErrorMessage }
                emitOnChange={ (e) => handleEmitOnChange(e, 'firstName') }
                emitOnValidate={ (e) => handleEmitValidate(e, 'firstName') }
                validState={ formState.firstName.validState }
                validation={ {
                  required: {
                    errorMessage: 'Je hebt nog geen naam ingevuld',
                  },
                } }
                customStyling={ {
                  container: {
                    className: 'grow',
                  },
                } }
              />
              <div className='flex-col flex md:flex-row gap-x-4'>
                <Input
                  label='Tussenvoegsel'
                  value={ formState.preposition.value }
                  isValid={ formState.preposition.isValid }
                  emitOnChange={ (e) => handleEmitOnChange(e, 'preposition') }
                  emitOnValidate={ (e) => handleEmitValidate(e, 'preposition') }
                  validState={ formState.preposition.validState }
                  customStyling={ {
                    container: {
                      className: 'md:max-w-[165px] mb-4 md:mb-0',
                    },
                  } }
                />
                <Input
                  label='Achternaam'
                  required
                  value={ formState.lastName.value }
                  isValid={ formState.lastName.isValid }
                  showErrorMessage={ formState.lastName.showErrorMessage }
                  emitOnChange={ (e) => handleEmitOnChange(e, 'lastName') }
                  emitOnValidate={ (e) => handleEmitValidate(e, 'lastName') }
                  validState={ formState.lastName.validState }
                  validation={ {
                    required: {
                      errorMessage: 'Je hebt nog geen achternaam ingevuld',
                    },
                  } }
                  customStyling={ {
                    container: {
                      className: 'grow',
                    },
                  } }
                />
              </div>
              <div className='flex-col flex md:flex-row gap-x-4'>
                <Input
                  label='E-mailadres'
                  required
                  value={ formState.email.value }
                  isValid={ formState.email.isValid }
                  showErrorMessage={ formState.email.showErrorMessage }
                  emitOnChange={ (e) => handleEmitOnChange(e, 'email') }
                  emitOnValidate={ (e) => handleEmitValidate(e, 'email') }
                  validState={ formState.email.validState }
                  validation={ {
                    required: {
                      validateFunction: notEmpty,
                      errorMessage: 'Je hebt nog geen e-maildres ingevuld',
                    },
                    pattern: {
                      validateFunction: isEmail,
                      errorMessage: 'Je hebt een ongeldig e-mailadres ingevuld. Kun je het controleren?',
                    },
                  } }
                  customStyling={ {
                    container: {
                      className: 'grow mb-4 md:mb-0',
                    },
                  } }
                />
                <Input
                  label='Telefoonnummer'
                  required
                  value={ formState.phone.value }
                  isValid={ formState.phone.isValid }
                  showErrorMessage={ formState.phone.showErrorMessage }
                  emitOnChange={ (e) => handleEmitOnChange(e, 'phone') }
                  emitOnValidate={ (e) => handleEmitValidate(e, 'phone') }
                  validState={ formState.phone.validState }
                  validation={ {
                    required: {
                      errorMessage: 'Je hebt nog geen telefoonnummer ingevuld',
                    },
                    pattern: {
                      validateFunction: isPhoneNumber,
                      errorMessage: 'Dit is geen geldig telefoonnummer. Kun je het controleren?',
                    },
                  } }
                  placeholder='bv: 0101234567/0612345678'
                  customStyling={ {
                    container: {
                      className: 'grow',
                    },
                  } }
                />
              </div>
              <Input
                type='textarea'
                placeholder='Stel hier uw vraag...'
                required
                label='Wat is uw vraag?'
                value={ formState.openQuestion.value }
                isValid={ formState.openQuestion.isValid }
                showErrorMessage={ formState.openQuestion.showErrorMessage }
                emitOnChange={ (e) => handleEmitOnChange(e, 'openQuestion') }
                emitOnValidate={ (e) => handleEmitValidate(e, 'openQuestion') }
                validState={ formState.openQuestion.validState }
                validation={ {
                  required: {
                    errorMessage: 'Je hebt nog geen vraag gesteld',
                  },
                } }
                customStyling={ {
                  container: {
                    className: 'grow mt-2',
                  },
                } }
              />
              <div>
                <div className='flex items-center mt-2 relative'>
                  <input
                    type='checkbox'
                    id='termsAccepted'
                    checked={ !!formState.termsAccepted.value }
                    className={ styling.checkbox + ' flex-none' }
                    onChange={ (e) => {
                      updateFormState('termsAccepted', 'value', e.target.checked);
                      updateFormState('termsAccepted', 'isValid', e.target.checked);
                      updateFormState('termsAccepted', 'showErrorMessage', false);
                    } }
                  />
                  { !!formState.termsAccepted.value && (
                    <span className='absolute left-[3px] pointer-events-none peer-checked:text-white peer-hover:text-primary'>
                      <Icon name='Done' className='!w-[13.58px]' />
                    </span>
                  ) }
                  <label
                    htmlFor='termsAccepted'
                    className='pl-4 cursor-pointer select-none'
                  >
                    Ik ga akkoord met de{ ' ' }
                    <Link href={ privacyLinkHref } legacyBehavior>
                      <a className='text-primary underline' target='_blank'>Privacyverklaring </a>
                    </Link>
                    <span>en </span>
                    <Link href={ termsLinkHref } legacyBehavior>
                      <a className='text-primary underline whitespace-nowrap' target='_blank'>Algemene voorwaarden.</a>
                    </Link>
                  </label>
                </div>
                { !formState.termsAccepted.isValid && formState.termsAccepted.showErrorMessage && (
                  <span className={ styling.errorMessage }>Je hebt nog geen toestemming gegeven</span>
                ) }
              </div>
              <div className='relative z-10 mt-8 mb-2 md:mb-4 xl:mb-8'>
                <ReCAPTCHA
                  sitekey={ process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY }
                  onChange={ (captchaCode) => {
                    if(!captchaCode) {
                      updateFormState('recaptcha', 'value', false);
                      updateFormState('recaptcha', 'isValid', false);
                      return;
                    }
                    updateFormState('recaptcha', 'value', captchaCode);
                    updateFormState('recaptcha', 'isValid', true);
                  } }
                  onExpired={ () => {
                    updateFormState('recaptcha', 'value', false);
                    updateFormState('recaptcha', 'isValid', false);
                  } }
                />
                { !formState.recaptcha.isValid && formState.recaptcha.showErrorMessage && (
                  <span className={ styling.errorMessage }>Controleer of je geen robot bent.</span>
                ) }
              </div>
              <div>
                <Button disabled={ loading }>
                  <span>{ submitButtonLabel }</span>
                </Button>
                { formErrorMessage && (
                  <span className={ `${ styling.errorMessage } md:w-1/2 ml-2` }>Fout tijdens het verzenden. Probeer het opnieuw!</span>
                ) }
              </div>
            </div>
          </form>
        ) }
      </div>
    </Grid>
  );
}
