import Grid from '@components/core/layout/Grid';
import { Image } from '@core/Image';
import Button from '@components/core/Button';
import Icon from '@components/core/Icon';
import useMediaQuery from '@helpers/hooks/useMediaQuery';
import { getInternalPath } from '@helpers/internalLink';

export default function ImageTextSection(props) {
  const isMobile = useMediaQuery(640);
  let ctaLink = undefined;

  if (props.cta && props.cta.link) {
    ctaLink = props.cta.link.internalLink ? getInternalPath(props.cta.link.internalLink) : props.cta.link.externalUrl;
  }

  return (
    <Grid>
      <div className={ `col-span-12 xl:col-span-6 mb-8 md:mb-12 xl:mb-0 relative w-[343px] h-[322px] md:w-full md:h-full order-1 ${ props.imageAlignLeft ? 'md:col-start-1' : 'md:!col-end-13 md:order-2' }` }>
        <Image
          sanityImage={ props.headerImage.image }
          className='rounded-[128px] md:rounded-[180px]'
          layout={ isMobile ? 'fill' : 'responsive' }
          objectFit='cover'
          sizes='(min-width: 1280px) 650px, (min-width: 768px) 850px, 400px'
        />
      </div>
      <div className={ `col-span-12 xl:col-span-4 order-2 ${ props.imageAlignLeft ? ' xl:col-start-8' : ' xl:col-start-1 xl:order-1' }` }>
        <div className='text-heading-md text-heading-md--semibold mb-4'>{ props.heading }</div>
        <div className='text-body-lg mb-[44px] whitespace-pre-line'>{ props.intro }</div>
        {
          ctaLink &&
          <Button type='text' href={ ctaLink } externalUrl={ props.cta.link.externalUrl ? true : false }>
            <span className='mr-3'>{ props.cta.label}</span>
            <Icon name={ 'ArrowForward' } />
          </Button>
        }
      </div>
    </Grid>
  );
}
