// TODO: Currently not a real core component
import { LegacyRef, useEffect, useRef, useState } from 'react';
import Icon from '@core/Icon';
import RichText from '@core/RichText';

export default function AccordionItem(props: any) {
  const accordionElement = useRef<HTMLDivElement>();
  const contentElement = useRef<HTMLDivElement>();

  const [ isExpanded, setIsExpanded ] = useState<boolean>(props.isExpanded || false);
  const [ expandCollapseTimeout, setExpandCollapseTimeout ] = useState<any>();

  const transitionDuration = 400;

  const styling = {
    container: sq`
      overflow-hidden
      border-b
      border-shade-100
      transition-all
      duration-[400ms]
    `,
    titleHolder: sq`
      flex
      ml-auto
      text-body-md
      text-body-md--semibold
      transition-transform
      duration-[400ms]
      py-4
      text-primary-800
      cursor-pointer

      lg:text-body-lg
      lg:text-body-lg--semibold
    `,
  };

  useEffect(() => {
    if (!accordionElement.current || !contentElement.current) {
      return;
    }

    clearTimeout(expandCollapseTimeout);

    if (isExpanded) {
      const prevHeight = accordionElement.current.offsetHeight;
      contentElement.current.style.display = 'block';
      accordionElement.current.style.height = 'auto';

      void accordionElement.current.offsetHeight;

      const nextHeight = accordionElement.current.offsetHeight;
      accordionElement.current.style.height = `${ prevHeight }px`;

      void accordionElement.current.offsetHeight;
      accordionElement.current.style.height = `${ nextHeight }px`;

      setExpandCollapseTimeout(setTimeout(() => {
        if (accordionElement && accordionElement.current !== undefined) {
          accordionElement.current.style.height = 'auto';
        }
      }, transitionDuration));

    } else {
      const prevHeight = accordionElement.current.offsetHeight;
      contentElement.current.style.display = 'none';
      accordionElement.current.style.height = 'auto';

      void accordionElement.current.offsetHeight;

      const nextHeight = accordionElement.current.offsetHeight;
      accordionElement.current.style.height = `${ prevHeight }px`;

      void accordionElement.current.offsetHeight;
      accordionElement.current.style.height = `${ nextHeight }px`;
      contentElement.current.style.display = 'block';

      setExpandCollapseTimeout(setTimeout(() => {
        if (accordionElement && accordionElement.current !== undefined) {
          accordionElement.current.style.height = 'auto';
        }

        if (contentElement && contentElement.current !== undefined) {
          contentElement.current.style.display = 'none';
        }
      }, transitionDuration));
    }
  }, [ isExpanded ]);

  if (!props.title) {
    return null;
  }

  return (
    <div className={ styling.container } ref={ accordionElement as LegacyRef<HTMLDivElement> | undefined }>
      <div
        className={ styling.titleHolder }
        onClick={ () => setIsExpanded(!isExpanded) }
        id='accordion-item'
      >
        <p className='mr-auto'>{ props.title }</p>
        <Icon name='ExpandMore' className={ `text-primary ml-4 shrink-0 transition-transform duration-500 ${ isExpanded ? 'rotate-180' : '' } ` } />
      </div>
      <div className={ 'hidden text-shade-800 transition-all' } ref={ contentElement as LegacyRef<HTMLDivElement> | undefined }>
        <RichText text={ props.description } />
      </div>
    </div>
  );
}
