import Select, { StylesConfig } from 'react-select';
// Tailwind to CSS-in-JS
import tw  from 'twin.macro';

export type TDropdownOption = {
  label: string,
  value: string,
} | undefined;

export type TDropdownOptions = TDropdownOption[];

interface IDropdownProps {
  onChange?: (newValue: { value: string; label: string; }) => void,
  options: TDropdownOptions,
  value: TDropdownOption,
  placeholder?: string,
  id: string,
  isMulti?: boolean,
  isSearchable?: boolean,
  errorMessage?: string,
  showErrorMessage?: boolean,
  label?: string,
  className?: string,
  closeMenuOnSelect?: boolean,
}

export default function Dropdown({
  options,
  value,
  placeholder,
  onChange,
  id,
  isMulti,
  isSearchable = false,
  errorMessage,
  showErrorMessage,
  label,
  className,
  closeMenuOnSelect = true,
}: IDropdownProps) {
  const emitOnChange = onChange;
  // Custom styling for react-select, see: https://react-select.com/styles
  const styles: StylesConfig = {
    control: (provided, state) => ({
      ...tw`flex items-center min-h-[48px] rounded-[2rem] bg-white border border-shade-100 focus:border-primary-200 hover:border-primary-200 cursor-pointer text-body-lg`,
      ...state.menuIsOpen && tw`rounded-bl-[0] rounded-br-[0]`,
      ...showErrorMessage && tw`border-error`,
    }),
    valueContainer: () => ({
      ...tw`flex items-center pl-6 mr-auto py-1`,
      ...isMulti && tw`max-w-[90%] md:max-w-[70%] xl:max-w-[90%] flex-wrap [& > input]:fixed`,
    }),
    singleValue: () => tw`text-body-lg relative`,
    indicatorsContainer: () => tw`flex items-center pr-4`,
    dropdownIndicator: () => tw`flex`,
    menu: () => tw`absolute py-2 bg-white w-full shadow-md rounded-bl-[2rem] rounded-br-[2rem] overflow-hidden border border-shade-100 border-t-0`,
    menuList: () => tw`max-h-[280px] overflow-y-auto overflow-x-hidden`,
    option: (provided, state) => ({
      ...tw`px-6 py-2 bg-white focus:bg-primary-50 hover:bg-primary-50 text-body-lg cursor-pointer`,
      ...state.isSelected && tw`text-primary font-semibold`,
    }),
    placeholder: () => ({
      ...tw`text-shade-700 text-body-lg font-default`,
    }),
    multiValue: (provided) => ({
      ...provided,
      ...tw`border border-shade-200 bg-white rounded-2xl bg-shade-50`,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      ...tw`hover:bg-error-100 m-1 hover:text-error`,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      ...tw`font-default text-body-sm`,
    }),
  };

  return (
    <div className={ className }>
      { label &&
        <div className='flex items-center mb-1'>
          <label htmlFor={ `react-select-${ id }-input` } className='text-body-md text-body-md--semibold' data-error={ showErrorMessage && errorMessage ? 'true' : 'false' }>{ label }</label>
        </div>
      }
      <Select
        key={ id }
        id={ id }
        isMulti={ isMulti }
        closeMenuOnSelect={ closeMenuOnSelect }
        instanceId={ id }
        options={ options }
        onChange={ emitOnChange }
        value={ value }
        classNamePrefix={ 'react-select' }
        isSearchable={ isSearchable }
        placeholder={ placeholder ?? null }
        components={ { IndicatorSeparator: () => null } }
        styles={ styles }
        aria-label={ id }
        isClearable={ true }
      />
      {
        errorMessage && errorMessage.length > 0 && showErrorMessage &&
        <span className='block w-full text-body-sm mt-2 text-error md:text-left'>{errorMessage}</span>
      }
    </div>
  );
}
