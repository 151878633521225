// TODO: Not a core component!
import React, { useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import Grid from '@components/core/layout/Grid';
import Button from '@components/core/Button';

import Icon from '@components/core/Icon';

import { getInternalPath } from '@helpers/internalLink';
import useMediaQuery from '@helpers/hooks/useMediaQuery';
import { slugify } from '@helpers/string';

const styling = {
  header: sq`
    fixed
    top-0
    w-full
    bg-primary-50
    z-50
    transition-colors
    duration-300
  `,
  mainNavContainer: sq`
    flex
    items-center
    col-span-12
    h-[56px]
    transition-all
    duration-300

    md:h-[88px]
  `,
  mainNavItem: sq`
    flex
    self-center
    items-center
    w-full
    px-4
    py-3
    text-primary-900
    text-body-md
    transition-colors
    duration-300

    md:px-6
    lg:px-8

    xl:py-2
    xl:font-normal
    xl:px-2

    hover:xl:text-primary
    focus:xl:text-primary

    2xl:px-3
  `,
  logo: sq`
    flex
    flex-shrink-0
    relative
    w-[185px]
    h-8
    mr-auto
    overflow-hidden

    xl:w-[232px]
    xl:h-10
    xl:mr-3
    2xl:mr-6
  `,
  navContainer: sq`
    fixed
    left-0
    top-[64px]
    bg-primary-50
    w-full
    shadow-lg
    duration-300
    z-40
    overflow-y-auto

    md:top-[104px]

    xl:overflow-hidden
    xl:shadow-none
    xl:static
    xl:left-auto
    xl:top-auto
    xl:w-auto
    xl:grid
    xl:grid-flow-col
    xl:gap-0
    xl:mr-auto
    xl:bg-transparent
    xl:pointer-events-auto
    xl:!h-[88px]
  `,
  subMenuShadow: sq`
    absolute
    left-0
    top-0
    w-full
    h-full
    bg-black/20
    transition-all
    duration-300
    pointer-events-none
    xl:hidden
  `,
  mobileMenuButton: sq`
    flex
    justify-center
    items-center
    w-8
    h-8
    ml-4

    xl:hidden
  `,
  subMenu: sq`
    fixed
    left-0
    top-[64px]
    bg-primary-50
    w-full
    duration-300
    overflow-hidden
    h-[calc(100%-64px)]
    z-40

    md:top-[104px]

    xl:top-[80px]
    xl:h-auto
    xl:z-30
    xl:rounded-bl-[32px]
    xl:rounded-br-[32px]
    xl:border-b-[16px]
    xl:border-b-primary
  `,
  subMenuActive: sq`
    visible
    translate-x-0
    pointer-events-auto

    xl:opacity-100
    xl:visible
    xl:translate-y-0
    xl:z-30
  `,
  subMenuInactive: sq`
    invisible
    translate-x-full
    pointer-events-none

    xl:translate-x-0
    xl:invisible
    xl:-translate-y-8
    xl:!h-0
  `,
  subMenuContent: sq`
    absolute
    w-full
    h-full
    will-change-transform

    xl:h-auto
    xl:py-12
  `,
  subMenuContentActive: sq`
    pointer-events-auto
    xl:opacity-100
    xl:z-40
    xl:!translate-x-0
    visible
  `,
  subMenuContentInactive: sq`
    pointer-events-none
    xl:opacity-0
    opacity-0
    invisible
  `,
  subMenuColumn: sq`
    flex
    flex-col
    col-span-12

    xl:col-span-3
  `,
  subMenuColumnTitle: sq`
    text-body-md
    text-body-md--semibold
    text-primary-900
    pb-2
    border-b
    border-shade-200
    mb-2
    empty:hidden

    xl:text-heading-xs
    xl:border-b-0
  `,
  subMenuItem: sq`
    py-1
    underline-offset-1
    text-body-md
    text-primary-900

    xl:py-0
    xl:text-body-md

    hover:text-primary
    hover:underline
    focus:text-primary
    focus:underline
  `,
  mobileSubMenuBackbutton: sq`
    relative
    w-full
    flex
    items-center
    h-12
    px-4

    bg-gray
    bg-white
    text-primary

    md:px-6
    lg:px-8
    xl:px-12
    xl:hidden
  `,
  ctaColumn: sq`
    grid
    grid-cols-1
    gap-x-6
    p-4
    flex
    flex-col
    col-span-12
    rounded-2xl
    bg-white
    h-fit

    md:grid-cols-2
    md:p-6

    xl:grid-cols-1
    xl:col-span-3
    xl:p-8
    xl:rounded-[2rem]
    xl:col-start-10
  `,
  ctaColumnDescription: sq`
    text-body-md
    text-shade-800
    mb-4
    xl:mb-6
  `,
};

export default function Navigation(props) {
  const router = useRouter();

  const [ activeSubMenu, setActiveSubMenu ] = useState<string|null>();
  const [ activeSubMenuBuffer, setActiveSubMenuBuffer ] = useState<string|null>();
  const [ activeSubMenuIndex, setActiveSubMenuIndex ] = useState<number|null>();
  const [ isAnimating, setIsAnimating ] = useState<boolean>(false);
  const [ mobileMenuOpen, setMobileMenuOpen ] = useState<boolean>(false);
  const isMobile = useMediaQuery(786);

  const getNavItemUrl = (navItem) => {
    if (!navItem.link) {
      return '';
    }

    return navItem.link.internalLink
      ? getInternalPath(navItem.link.internalLink) + (navItem.linkWithFilter ? `?productType=${ navItem.linkWithFilter }` : '') + (navItem.linkToAccordionBlock ? `/#${ slugify(navItem.linkToAccordionBlock) }` : '')
      : navItem.link.externalUrl;
  };

  const handleSubMenuEnter = useCallback((key) => {
    const nextItemIndex = props.navItems.findIndex(navItem => navItem._key === key);
    if (activeSubMenuIndex === null) {
      setIsAnimating(true);

      setTimeout(() => {
        setIsAnimating(false);
      }, 300);
    }

    setActiveSubMenuIndex(nextItemIndex);
    setActiveSubMenuBuffer(key);
    setActiveSubMenu(key);

    if (window.innerWidth < 1024) {
      return;
    }

    const currentSubMenu: HTMLElement | null = document.querySelector(`[data-submenu-id='${ key }']`);

    if (!currentSubMenu) {
      return;
    }
    const currentSubMenuHeight = currentSubMenu.offsetHeight;
    const subMenuHolder: HTMLElement | null= document.querySelector('[data-js-submenu-holder]');
    if (subMenuHolder) {
      subMenuHolder.style.height = `${ currentSubMenuHeight }px`;
    }

  }, [ activeSubMenuIndex, props.navItems ]);

  const handleSubMenuLeave = () => {
    setActiveSubMenuBuffer(null);
  };

  const closeSubMenu = () => {
    const subMenuHolder: HTMLElement | null = document.querySelector('[data-js-submenu-holder]');

    if (subMenuHolder) {
      subMenuHolder.style.cssText = '';
    }

    setActiveSubMenu(null);
    setActiveSubMenuIndex(null);

    setIsAnimating(true);

    setTimeout(() => {
      setIsAnimating(false);
    }, 300);
  };

  useEffect(() => {
    const t = setTimeout(() => {
      if (!activeSubMenuBuffer) {
        closeSubMenu();
      }
    }, 200);

    return () => clearTimeout(t);
  }, [ activeSubMenuBuffer ]);

  // Render the main nav item
  const renderNavItem = (navItem) => {
    if (!navItem) {
      return;
    }

    const navItemSlug = navItem.link?.internalLink?.slug.current || '';
    const navItemUrl = getNavItemUrl(navItem);

    return (
      <div
        key={ navItem._key }
        onClick={ () => isMobile ? handleSubMenuEnter(navItem._key) : false }
        onMouseEnter={ () => isMobile ? false : handleSubMenuEnter(navItem._key) }
        className='flex self-center relative group bg-white xl:bg-transparent w-full'
      >
        <div
          className={ sq`
            absolute bottom-0 w-full h-[1px] bg-shade-200 pointer-events-none xl:hidden
          ` }
        />
        <Link href={ navItemUrl } legacyBehavior>
          <a
            onClick={ (event) => {
              if (window.innerWidth > 1023) {
                // Desktop
                closeSubMenu();
              } else {
                // Mobile
                setActiveSubMenu(navItem._key);

                event.preventDefault();
                return false;
              }
            } }

            onTouchEnd={ (e) => {
              handleSubMenuEnter(navItem._key);
              e.preventDefault();
            } }

            className={ sq`
              ${ styling.mainNavItem }
              ${ router.query.slug && router.query.slug[0] === navItemSlug ? 'xl:text-primary' : '' }
            ` }
          >
            { navItem.label }
            <Icon name='ChevronRight' className='text-primary xl:hidden ml-auto' />
            <Icon name='ExpandMore' className='hidden ml-1 h-5 w-5 self-center xl:block' />
          </a>
        </Link>

      </div>
    );
  };

  // Render the sub nav for each main nav item
  const renderSubMenu = (navItem) => {
    return (
      <div
        data-submenu-id={ navItem._key }
        className={ sq`
          ${ styling.subMenuContent }

          ${ isAnimating ? 'duration-300' : 'durations-[0ms]' }

          ${ activeSubMenu === navItem._key ? styling.subMenuContentActive : styling.subMenuContentInactive }
        ` }
        key={ navItem._key }
      >
        <button
          className={ styling.mobileSubMenuBackbutton }
          onClick={ () => {
            closeSubMenu();
          } }
        >
          <div
            className={ sq`absolute bottom-0 left-0 w-full h-[1px] bg-shade-200 pointer-events-none xl:hidden` }
          />
          <Icon name='ChevronLeft' className='mr-2' /> <span className='text-heading-sm font-semibold'>{ navItem.label }</span>
        </button>
        <Grid
          className={ sq`
            !flex
            flex-col
            h-[calc(100%-48px)]
            py-6
            gap-y-4
            overflow-auto

            xl:!grid
            md:h-[calc(100%-88px)]
            xl:h-auto
            xl:py-0
            xl:gap-y-0
            xl:overflow-clip
          ` }
        >
          { navItem.subMenu && navItem.subMenu.map(column => renderColumn(column)) }
          { navItem.ctaButtons && renderCtaColumn(navItem) }
        </Grid>
      </div>
    );
  };

  // Render a specific column of a sub nav
  const renderColumn = (column) => {
    return (
      <div
        key={ column._key }
        className={ styling.subMenuColumn }
      >
        <p className={ styling.subMenuColumnTitle }>{ column.title }</p>
        <div className={ sq`grid gap-2 xl:mb-0` }>
          { column.columnItems && column.columnItems.map(subNavItem => renderSubMenuItem(subNavItem)) }
        </div>
      </div>
    );
  };

  const renderCtaColumn = (navItem) => {
    return (
      <div
        className={ styling.ctaColumn }
      >
        <div>
          <p className={ 'text-heading-xs font-semibold !border-0 mb-2' }>{ navItem.ctaTitle }</p>
          <p className={ styling.ctaColumnDescription }>{ navItem.ctaDescription }</p>
        </div>
        <div className={ sq`grid gap-2 lg:gap-4 xl:mb-0` }>
          { navItem.ctaButtons && navItem.ctaButtons.map(subNavItem => renderSubMenuItem(subNavItem)) }
        </div>
      </div>
    );
  };

  // Render sub nav item (link or button)
  const renderSubMenuItem = (subNavItem) => {
    const subNavItemUrl = getNavItemUrl(subNavItem);

    const getTarget = () => {
      const externalUrl = subNavItem.link?.externalUrl;

      if (externalUrl && subNavItemUrl.includes('prokinozorg.nl')) {
        return '_self';
      }

      return externalUrl ? '_blank' : '_self';
    };

    if (subNavItem.type === 'button') {
      return (
        <Button
          key={ subNavItem._key }
          href={ subNavItemUrl }
          onClick={ () => toggleMobileMenuOpen(false) }
          size='small'
          externalUrl={ subNavItem.link?.externalUrl }
          target={ getTarget() }
        >
          { subNavItem.label }
        </Button>
      );
    }

    return (
      <Link
        key={ subNavItem._key }
        href={ subNavItemUrl }
        legacyBehavior
      >
        <a
          className={ styling.subMenuItem }
          onClick={ () => toggleMobileMenuOpen(false) }
          target={ getTarget() }
        >
          { subNavItem.label }
        </a>
      </Link>
    );
  };

  const toggleMobileMenuOpen = (providedState?) => {
    if (window.innerWidth < 1024) {
      const newState = providedState !== undefined ? providedState : !mobileMenuOpen;

      setMobileMenuOpen(newState);

      if (newState) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }

    closeSubMenu();
  };

  return (
    <>
      <div
        className={ styling.header }
      >
        <div className='h-2 relative md:h-4 transition-all duration-300'>
          <Image
            src={ `/assets/domain-bars/${ props.theme }.svg` }
            alt='Decorative bar'
            fill={ true }
            priority={ true }
            sizes='(min-width: 1280px) 1440px, (min-width: 768px) 750px, 640px'
            unoptimized={ true }
          />
        </div>
        <Grid>
          <div
            className={ styling.mainNavContainer }
          >
            <Link href={ `${ props.theme === 'corporate' ? '/' : `/${ props.theme }` }` } legacyBehavior>
              <a
                className={ styling.logo }
              >
                <Image
                  src={ `/assets/logos/${ props.theme }.svg` }
                  alt='Logo Meander-Prokino'
                  width={ 232 }
                  height={ 40 }
                  className='object-contain object-left'
                  sizes='232px'
                  unoptimized={ true }
                />
              </a>
            </Link>
            <nav
              onMouseLeave={ handleSubMenuLeave }
              className={ `${ styling.navContainer } ${ mobileMenuOpen ? '!h-[calc(100%-64px)]' : '!h-0' }` }
            >
              { props.navItems.map(navItem => renderNavItem(navItem)) }

              {/* Sub menu shadow overlay */}
              <div className={ `${ styling.subMenuShadow } ${ activeSubMenu ? 'opacity-100' : 'delay-100 opacity-0' }` } />

              {/* Main nav CTA buttons mobile at bottom */}
              <Grid className='grid md:hidden'>
                <div className='flex flex-col gap-y-2 py-6 col-span-12'>
                  { props.ctaButton1 && (
                    <Button href={ getNavItemUrl(props.ctaButton1) } size='small' externalUrl={ props.ctaButton1?.link.externalUrl }>
                      { props.ctaButton1.label }
                    </Button>
                  ) }

                  { props.ctaButton2 && (
                    <Button href={ getNavItemUrl(props.ctaButton2) } size='small' externalUrl={ props.ctaButton2?.link.externalUrl }>
                      <span>{ props.ctaButton2.label }</span>
                    </Button>
                  ) }
                </div>
              </Grid>
            </nav>

            {/* Main nav CTA buttons desktop */}
            <div className='hidden md:flex gap-x-2'>
              { props.ctaButton1 && (
                <Button href={ getNavItemUrl(props.ctaButton1) } size='small' externalUrl={ props.ctaButton1?.link.externalUrl }>
                  { props.ctaButton1.label }
                </Button>
              ) }

              { props.ctaButton2 && (
                <Button href={ getNavItemUrl(props.ctaButton2) } size='small' externalUrl={ props.ctaButton2?.link.externalUrl }>
                  { props.ctaButton2.label }
                </Button>
              ) }
            </div>

            {/* Mobile menu button */}
            <button
              type='button'
              className={ styling.mobileMenuButton }
              onClick={ () => toggleMobileMenuOpen() }
              aria-label={ mobileMenuOpen ? 'close-menu' : 'open-menu' }
            >
              { mobileMenuOpen
                ? <Icon name='Close' />
                : <Icon name='Menu' />
              }
            </button>
          </div>
        </Grid>
      </div>

      {/* Sub menu */}
      <div
        onMouseEnter={ () => handleSubMenuEnter(activeSubMenu) }
        onMouseLeave={ isMobile ? () => false : handleSubMenuLeave }
        data-js-submenu-holder
        className={ sq`
          ${ styling.subMenu }
          ${ activeSubMenu ? styling.subMenuActive : styling.subMenuInactive }
          !z-50
        ` }
      >
        { props.navItems.map((navItem) => renderSubMenu(navItem)) }
      </div>

      {/* Extra layer for catching "mouse leave" event for touch devices */}
      <div
        className={ sq`
          fixed
          w-full
          h-full

          ${ !activeSubMenu ? 'hidden' : '' }
        ` }
        onTouchEnd={ () => {
          closeSubMenu();
        } }
      />

      {/* Header spacer */}
      <div className={ 'h-[64px] md:h-[104px] transition-all bg-primary-50' } />
    </>
  );
}
