import Link from 'next/link';
import { useEffect, useState } from 'react';
export interface IButtonProps {
  type?: 'solid' | 'text' | 'ghost' | 'icon',
  size?: 'normal' | 'small',
  className?: string, // Custom styling
  href?: string,  // Need a link? insert href
  onClick?: React.MouseEventHandler, // Need a functional button; Insert onClick
  localeDetection?: boolean,
  query?: {[key: string]: any},
  prefetch?: boolean,
  children: React.ReactNode,
  externalUrl?: boolean,
  disabled?: boolean,
  ariaLabel?: string,
  target?: '_blank' | '_self',
}

const style = {
  solid: {
    default: `
      inline-flex
      items-center
      justify-center
      text-white
      bg-cta-500
      rounded-full
      group
      transition-all
      outline-none

      hover:bg-cta-400
      focus:bg-cta-400
      focus:shadow-cta-200
      focus:shadow-[inset_0_0_1px_1px]
      active:bg-cta-300
      disabled:bg-shade-50
      disabled:text-shade-400
    `,
    size: {
      small: 'text-body-md text-body-md--semibold py-2.5 px-4',
      normal: 'text-body-lg text-body-lg--semibold py-3 px-6',
    },
  },
  text: {
    default: `
      inline-flex
      items-center
      justify-center
      text-cta
      bg-inherit
      rounded-full
      group
      transition-all
      outline-none

      hover:text-cta-400
      focus:shadow-cta
      active:text-cta-300
      disabled:text-shade-400
    `,
    size: {
      small: 'text-body-md text-body-md--semibold py-2.5',
      normal: 'text-body-lg text-body-lg--semibold py-3',
    },
  },
  ghost: {
    default: `
      inline-flex
      items-center
      justify-center
      text-cta
      bg-cta-50
      rounded-full
      group
      transition-all
      outline-none

      hover:text-cta-400
      focus:text-cta
      focus:shadow-cta
      focus:shadow-[inset_0_0_1px_1px]
      active:text-cta-600
      active:shadow-shade-200
      active:shadow-[inset_0_0_1px_1px]
      disabled:bg-shade-50
      disabled:!text-shade-400
    `,
    size: {
      small: 'text-body-md text-body-md--semibold py-2.5 px-4',
      normal: 'text-body-lg text-body-lg--semibold py-3 px-6',
    },
  },
  icon: { // TODO: Still need to add icon styling
    default: `
      inline-flex
      items-center
      text-white
      bg-cta
      rounded-full
      group
      transition-all
      outline-none

      hover:text-cta-400
      hover:bg-cta-50
      hover:mix-blend-multiply
      focus:text-cta-400
      focus:shadow-cta
      focus:shadow-[inset_0_0_1px_1px]
      active:text-cta
      active:bg-cta-100
      disabled:bg-transparent
      disabled:text-shade-400

      group-hover:text-cta-400
      group-hover:bg-cta-50
      group-hover:mix-blend-multiply
      group-focus:text-cta-400
      group-focus:shadow-cta
      group-focus:shadow-[inset_0_0_1px_1px]
      group-active:text-cta
      group-active:bg-cta-100
      group-disabled:bg-transparent
      group-disabled:text-shade-400
    `,
    size: {
      small: 'p-[10px]',
      normal: 'p-3',
    },
  },
};

const Button = ({
  type = 'solid',
  size = 'normal',
  className,
  href,
  onClick,
  localeDetection = true,
  prefetch = true,
  children,
  externalUrl = false,
  disabled,
  ariaLabel,
  target,
}: IButtonProps) => {
  const prefetchProp = {
    [prefetch ? '' : 'prefetch']: prefetch,
  };

  const localeProp = {
    [localeDetection ? '' : 'locale' ]: false,
  };

  const isDisabled = disabled ? { disabled: true } : '';
  const [ hrefWithUTM, setHrefWithUTM ] = useState('');

  useEffect(() => {
    // On first render the sessionStorage is not yet set, so we need to wait a bit
    setTimeout(() => {
      if (
        externalUrl &&
        href &&
        href.includes('aanmeldenkinderopvang.nl') &&
        sessionStorage.getItem('utm_content')
      ){
        setHrefWithUTM(`${ href }&utm_content=${ sessionStorage.getItem('utm_content') }`);
      }
    }, 1);
  }, [ externalUrl, href ]);

  return (
    <>
      { href ?
        <Link
          href={ hrefWithUTM.length > 0 ? hrefWithUTM: href }
          { ...prefetchProp }
          { ...localeProp }
          legacyBehavior
        >
          <a
            target={ target ?? externalUrl ? '_blank' : '_self' }
            className={ sq`${ style[type].default } ${ style[type].size[size] } ${ className as string | ((...args: any[]) => string) }` }>
            { children }
          </a>
        </Link> :
        <button
          { ...isDisabled }
          className={ sq`${ style[type].default } ${ style[type].size[size] } ${ className as string | ((...args: any[]) => string) }` }
          onClick={ onClick }
          aria-label={ ariaLabel }
        >
          { children }
        </button>
      }
    </>
  );
};

export default Button;
