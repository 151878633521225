import { Image } from '@components/core/Image';
import Icon from '@components/core/Icon';
import Button from '@components/core/Button';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useRef } from 'react';
import 'swiper/css';

interface IImagesCarousel {
  images: any,
  description?: string,
  classNameName: boolean,
  sectionFullWidth: boolean,
  autoPlaySpeed: number
}

export default function ImagesCarousel(props: IImagesCarousel) {

  const swiperRef = useRef<any>(null);
  const { images, autoPlaySpeed, sectionFullWidth } = props;

  return (
    <div className={ sectionFullWidth
      ? 'w-full relative flex items-center'
      : 'relative flex items-center max-w-content px-4 md:px-6 lg:px-8 2xl:px-12 m-auto'
    }>
      {
        sectionFullWidth ?
          <Button type='icon' className='left-3 lg:left-10 absolute z-10 text-cta hover:!mix-blend-normal -translate-y-[27px]' aria-label='Carousel previous' onClick={ () => swiperRef.current.slidePrev() }>
            <Icon name={ 'ArrowBack' } className='!h-4 !w-4 md:!h-6 md:!w-6' />
          </Button>
          :
          <Button type='icon' className='left-3 lg:left-10 xl:left-32 absolute z-10 text-cta -translate-y-[27px] hover:!mix-blend-normal' aria-label='Carousel previous' onClick={ () => swiperRef.current.slidePrev() }>
            <Icon name={ 'ArrowBack' } className='!h-4 !w-4 md:!h-6 md:!w-6' />
          </Button>
      }

      <Swiper
        loop={ true }
        spaceBetween={ 20 }
        grabCursor={ true }
        slidesPerView={ 1 }
        autoplay={ {
          delay: autoPlaySpeed ? autoPlaySpeed * 1000 : 6000,
          disableOnInteraction: false,
        } }
        modules={ [ Autoplay ] }
        onInit={ (swiper) => {
          swiperRef.current = swiper;
        } }
      >
        {
          images.map((image, i) =>
            <SwiperSlide key={ i }>
              {
                <div className='relative w-full h-[450px]'>
                  <div className={ sectionFullWidth ? 'relative w-full h-[calc(100%-27px)]': 'max-h-96 max-w-[840px] h-[400px] mx-auto px-5' }>
                    <Image
                      sanityImage={ image }
                      objectFit={ 'cover' }
                      layout={ 'fill' }
                      className={ sectionFullWidth ? '' : 'rounded-[32px]' }
                      sizes={ sectionFullWidth ? '(min-width: 1280px) 1440px, (min-width: 768px) 750px, 640px' : '(min-width: 1280px) 840px, (min-width: 768px) 750px, 640px' }
                    />
                  </div>
                  <div className={ sectionFullWidth ? 'px-5' : 'max-w-[840px] mx-auto px-5' }>
                    { image.caption &&
                      <p className='text-body-sm md:text-body-md mt-2 text-shade-700'>{ image.caption }</p>
                    }
                  </div>
                </div>
              }
            </SwiperSlide>
          )
        }
      </Swiper>
      {
        sectionFullWidth ?
          <Button type='icon' className='right-3 lg:right-10 text-cta absolute z-10 hover:!mix-blend-normal -translate-y-[27px]' aria-label='Carousel next' onClick={ () => swiperRef.current.slideNext() }>
            <Icon name={ 'ArrowForward' } className='!h-4 !w-4 md:!h-6 md:!w-6' />
          </Button> :
          <Button type='icon' className='right-3 lg:right-10 xl:right-32 absolute z-10 text-cta -translate-y-[27px] hover:!mix-blend-normal' aria-label='Carousel next' onClick={ () => swiperRef.current.slideNext() }>
            <Icon name={ 'ArrowForward' } className='!h-4 !w-4 md:!h-6 md:!w-6' />
          </Button>
      }
    </div>
  );
}
