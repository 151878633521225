import Grid from '@components/core/layout/Grid';
import { Image } from '@components/core/Image';
import Button from '@components/core/Button';

import { getInternalPath } from '@helpers/internalLink';

const HeroBubbles = ({ className }: { className?: string }) => (
  <svg viewBox='0 0 258 315' xmlns='http://www.w3.org/2000/svg' className={ className }>
    <circle cx='181' cy='160' r='77' className='fill-primary-400' />
    <circle cx='52' cy='52' r='52' className='fill-primary-300' />
    <circle cx='174' cy='295' r='20' className='fill-primary-200' />
  </svg>
);

const styling = {
  default: sq`
    col-span-12
    mb-4

    md:mb-6
    2xl:mb-8
  `,
  imageContainer: sq`
    relative w-[calc(100%-1rem)]
    h-[160px]

    ml-4
    mt-4
    mb-4

    md:h-[240px]
    xl:h-[320px]
  `,
  imageBackground: sq`
    absolute
    -left-4
    -bottom-4
    top-12
    w-1/2
    rounded-[1.5rem]
    bg-primary

    md:rounded-[2rem]
    xl:rounded-[2.5rem]
  `,
  image: sq`
    w-full
    h-full
    rounded-[1rem]
    bg-primary-100

    md:rounded-[1.5rem]
    xl:rounded-[2rem]
  `,
  imageBubbles: sq`
    absolute
    -bottom-4
    right-[5%]
    h-[90%]
    w-auto

    md:right-[10%]
  `,
  heading: sq`
    text-heading-lg
    mb-2
    xl:text-heading-xl
    xl:mb-4
    2xl:mb-0
    2xl:text-heading-3xl
  `,
  leftColumn: sq`
    self-start
    col-span-12
    2xl:col-span-6
  `,
  rightColumn: sq`
    self-start
    col-span-12

    md:col-span-8
    2xl:col-span-5
    2xl:col-start-8
  `,
};

const getNavItemUrl = (navItem) => {
  if (!navItem.link) {
    return '';
  }

  return navItem?.link?.internalLink
    ? getInternalPath(navItem.link.internalLink)
    : navItem.link.externalUrl;
};

export default function HeaderTopImage(props: any) {
  const {
    heading,
    intro,
    image,
    ctaButton1,
    ctaButton2,
  } = props;

  return (
    <Grid className='pb-8 md:pb-12 xl:pb-16'>
      <div className={ styling.default }>
        <div className={ styling.imageContainer } >
          <div className={ styling.imageBackground }></div>
          <Image
            sanityImage={ image.image }
            className={ styling.image }
            layout='fill'
            objectFit='cover'
            sizes='(min-width: 1280px) 1440px,(min-width: 768px) 750px, 640px'
          />
          <div className={ styling.imageBubbles }>
            <HeroBubbles className='h-full' />
          </div>
        </div>
      </div>
      <div className={ styling.leftColumn }>
        <h1 className={ styling.heading }>{ heading }</h1>
      </div>
      <div className={ styling.rightColumn }>
        <p className={ sq`text-body-xl` }>{ intro }</p>
        { (ctaButton1 || ctaButton2) && (
          <div className='flex flex-col md:flex-row items-start gap-4 mt-4 md:mt-6 2xl:mt-8'>
            { ctaButton1 && (
              <Button href={ getNavItemUrl(ctaButton1) } type='solid'  externalUrl={ ctaButton1?.link?.externalUrl }>
                { ctaButton1.label }
              </Button>
            ) }

            { ctaButton2 && (
              <Button href={ getNavItemUrl(ctaButton2) } type='ghost' className='mix-blend-multiply'  externalUrl={ ctaButton2?.link?.externalUrl }>
                { ctaButton2.label }
              </Button>
            ) }
          </div>
        ) }
      </div>
    </Grid>
  );
}
