import { PortableText } from '@portabletext/react';
import Icon from '@core/Icon';
import Link from 'next/link';
import Button from '@core/Button';
import { getInternalPath } from '@helpers/internalLink';

const portableTextComponents = {
  block: {
    h1: ({ children }: any) => <h1 className='mb-2 md:mb-4 text-heading-2xl md:text-heading-3xl text-primary'>{ children }</h1>,
    h2: ({ children }: any) => <h2 className='mb-2 md:mb-4 text-heading-md md:text-heading-lg'>{ children }</h2>,
    h3: ({ children }: any) => <h3 className='mb-2 md:mb-4 text-heading-sm md:text-heading-md'>{ children }</h3>,
    h4: ({ children }: any) => <h4 className='mb-2 md:mb-4 text-heading-xs md:text-heading-sm'>{ children }</h4>,
    subtitle: ({ children }: any) => <p className='mb-2 md:mb-4 text-heading-sm md:text-heading-md'>{ children }</p>,
    intro: ({ children }: any) => <p className='mb-4 md:mb-6 xl:mb-8 text-body-lg md:text-body-xl'>{ children }</p>,
    normal: ({ children }: any) => {
      // TODO: Add correct spacing
      return children[0] === ''
        ? <span className='inline-block mb-6'></span>
        :  <p className='text-body-md md:text-body-lg mb-6'>{ children }</p>;
    },
    normal__heavy: ({ children }: any) => {
      // TODO: Add correct spacing
      return children[0] === ''
        ? <span className='inline-block mb-6'></span>
        : <p className='text-body-md md:text-body-lg text-body-md--semibold md:text-body-lg--semibold mb-6'>{ children }</p>;
    },
    small: ({ children }: any) => {
      // TODO: Add correct spacing
      return children[0] === ''
        ? <span className='inline-block mb-4'></span>
        :  <p className='text-body-sm md:text-body-md mb-4'>{ children }</p>;
    },
  },
  marks: {
    strong: ({ children }: any) => <strong className='font-semibold'>{ children }</strong>,
    link: ({ value, children }: any) => {
      const { internalLink, externalUrl } = value;
      const href = internalLink ? getInternalPath(internalLink) : externalUrl;
      const target = externalUrl ? '_blank' : '_self';
      return (

        <Link href={ href && href !== undefined ? href : '' } legacyBehavior>
          <a
            className={ sq`
                text-primary
                hover:text-primary-400
                focus:text-primary-600
                active:text-primary-300
              ` }
            target={ target }>
            { children }
          </a>
        </Link>
      );
    },
    buttonRichtext: ({ value, children }: any) => {
      const { link: { internalLink, externalUrl }, type } = value;
      const link = internalLink ? getInternalPath(internalLink) : externalUrl;
      return (
        <Button type={ type } href={ link } externalUrl={ !internalLink }>{ children }</Button>
      );
    },
  },
  list: {
    bullet: ({ children }: any) =>
      <ul className='list-disc mb-6 pl-8'>{ children }</ul>,
    number: ({ children }: any) =>
      <ol className='list-decimal mb-6 pl-8'>{ children }</ol>,
    checkmark: ({ children }: any) =>
      <ul className='checkmark-list mb-6 pl-2'>{ children }</ul>,
  },
  listItem: {
    bullet: ({ children }: any) =>
      <li className='text-body-md sm:text-body-lg mb-4 text-primary'>
        <span className='text-shade-900 relative left-4'>{ children }</span>
      </li>,
    number: ({ children }: any) =>
      <li className='text-body-md sm:text-body-lg mb-4 text-primary'>
        <span className='text-shade-900 relative left-4'>{ children }</span>
      </li>,
    checkmark: ({ children }: any) =>
      <li className='text-body-md sm:text-body-lg flex mb-4'>
        <Icon name='Check' className='text-primary' />
        <span className='ml-4 flex-1'>{ children }</span>
      </li>,
  },
};

interface IRichText {
  text: any
}

export default function RichText({ text }: IRichText) {
  return (
    <>
      {
        text &&
        <PortableText
          value={ text }
          components={ portableTextComponents }
        />
      }
    </>
  );
}
