import React, { useRef } from 'react';
import RichText from '@components/core/RichText';
import Grid from 'components/core/layout/Grid';

import { slugify } from '@helpers/string';
import AnchorNav from '@components/core/AnchorNav';
import AccordionItem from '@components/core/AccordionItem';

const styling = {
  offset: sq`
    w-full
    pointer-events-none
    mt-[-15rem]
    pt-[15rem]

    md:mt-[-16rem]
    md:pt-[16rem]

    xl:mt-[-136px]
    xl:pt-[136px]
  `,
  title: sq`
    col-span-12
    mb-2
    text-heading-md
    text-primary-900
    lg:mb-4
    lg:text-heading-lg
  `,
  description: sq`
    mb-4
    text-shade-800
    col-span-12
    text-lg
    xl:mb-8
  `,
};

interface IAccordionSectionProps {
  anchorNavEnabled: boolean,
  accordionBlocks: {
    title: string,
    description: any,
    accordionItems: {
      title: string,
      description: any,
    }[],
  }[],
}

export default function AccordionSection({ anchorNavEnabled, accordionBlocks }: IAccordionSectionProps) {
  // Allow anchor nav to be enabled through the prop, otherwise use the length of the blocks
  anchorNavEnabled = typeof anchorNavEnabled === 'boolean'
    ? anchorNavEnabled
    : accordionBlocks?.length > 1
      ? true
      : false;

  const sectionRef = useRef<HTMLDivElement>();

  return (
    <div ref={ sectionRef }>
      <Grid className='gap-y-8 lg:gap-y-16 xl:gap-y-24' >
        { anchorNavEnabled && (
          <AnchorNav
            headings={ accordionBlocks.map(block => { return { title: block.title, id: slugify(block.title) }; }) }
            sectionRef={ sectionRef }
          />
        ) }
        { Array.isArray(accordionBlocks) && accordionBlocks?.map((accordionBlock, accordionBlockIndex) => (
          <React.Fragment key={ accordionBlockIndex }>
            <div className={ `relative col-span-12 xl:col-span-8 ${ anchorNavEnabled ? 'xl:col-start-5' : 'xl:col-start-3' }` }>
              { /* Extra div for offsetting anchor link position */ }
              <div
                id={ slugify(accordionBlock.title) }
                data-js-anchor-id={ slugify(accordionBlock.title) }
                className={ styling.offset }
              />
              <h2 className={ styling.title }>{ accordionBlock.title }</h2>
              <div className={ styling.description }>
                <RichText text={ accordionBlock.description?.text } />
              </div>
              <div className={ sq`col-span-12` }>
                { accordionBlock.accordionItems && accordionBlock.accordionItems.map((accordionItem, accordionItemIndex) => (
                  <React.Fragment key={ accordionItemIndex }>
                    <AccordionItem
                      title={ accordionItem.title }
                      description={ accordionItem.description?.text }
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </React.Fragment>
        ))}
      </Grid>
    </div>
  );
}
