export default function Grid({ children, className }: { children: React.ReactNode, className?: string }) {
  return (
    <div className={
      sq`
        grid
        grid-cols-12
        gap-x-4
        md:gap-x-6
        lg:gap-x-8
        max-w-content
        mx-auto
        px-4
        md:px-6
        lg:px-8
        2xl:px-12
        ${ className ? className : '' }
      ` }
    >
      { children }
    </div>
  );
}

export function GridNested({ children, className }: { children: React.ReactNode, className?: string }) {
  return (
    <div className={
      sq`
        grid
        grid-cols-12
        gap-x-4
        md:gap-x-6
        lg:gap-x-8
        ${ className ? className : '' }
      ` }
    >
      { children }
    </div>
  );
}
