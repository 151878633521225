import React from 'react';
/* Sections */
import * as Sections from '@components/sections';
/* Helpers */
import { capitalizeFirstChar } from '@helpers/string';

function resolveSections(section: any) {
  const SectionComponent = section._type === 'component'
    ? Sections[capitalizeFirstChar(section.sections[0]._type)]
    : Sections[capitalizeFirstChar(section._type)];

  if (SectionComponent) {
    return SectionComponent;
  }
  return null;
}

interface Isection {
  _type: string,
  _key: string,
  sectionColor: 'white' | 'black' | 'orange' | 'grey',
  sectionSpacing: 'md' | 'l',
  sectionBottomSpacing: boolean,
  sections: any
}

// TODO: Add type/interface for section
export default function SectionBox(props: any) {
  const { sections, locale } = props;

  if (!Array.isArray(sections)) {
    return null;
  }

  return (
    <>
      { sections.map((section: Isection) => {
        const SectionComponent = resolveSections(section);
        const sectionProps = section._type === 'component'
          ? section.sections[0]
          : section;
        return !SectionComponent
          ? <div key={ section._key } className={ 'text-[#f00] text-[2rem]' }>
            { `Missing section ${ section._type } ${ section._type === 'component' ? `(${ sectionProps._type })` : '' }` }
          </div>
          : (
            <section key={ section._key } className={
              sq`
                ${ sectionProps.sectionBottomSpacing ? 'pt-8 md:pt-16 xl:pt-24 pb-0' : 'py-8 md:py-16 xl:py-24' }
                ${ sectionProps.sectionBackgroundColor ? 'bg-primary-50' : '' }
              `
            }>
              <SectionComponent
                { ...sectionProps }
                locale={ locale }
              />
            </section>
          );
      })}
    </>
  );
}
