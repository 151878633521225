import Grid from '@components/core/layout/Grid';
import RichText from '@components/core/RichText';
import Button from '@components/core/Button';

import { getInternalPath } from '@helpers/internalLink';

const getNavItemUrl = (navItem) => {
  if (!navItem.link) {
    return '';
  }

  return navItem?.link?.internalLink
    ? getInternalPath(navItem.link.internalLink)
    : navItem.link.externalUrl;
};

const lookUp = {
  BSO: 'Buitenschoolse opvang',
  POV: 'Peuteropvang',
  KDV: 'Kinderdagverblijf',
  ZJK: 'Zorg Jonge Kind',
};

export default function HeaderSimple(props: any) {
  const {
    heading,
    intro,
    ctaButton1,
    ctaButton2,
    products,
  } = props;

  return (
    <>
      <Grid className='pt-8 md:pt-12 xl:pt-16 pb-8 md:pb-12 xl:pb-24'>
        <div className='col-span-12 mb-2 md:mb-4 text-center'>
          <h1 className='text-heading-lg mb-2 xl:text-heading-xl xl:mb-4 2xl:text-heading-3xl'>
            { heading }
          </h1>
        </div>
        <div className='col-span-12 mb-2 md:mb-4 md:text-center xl:col-start-2 xl:col-span-10 2xl:col-start-3 2xl:col-span-8'>
          <RichText text={ intro?.text } />
          { (ctaButton1 || ctaButton2) && (
            <div className='flex flex-col md:flex-row items-start justify-center gap-4 mt-4 md:mt-6 2xl:mt-8'>
              { ctaButton1 && (
                <Button href={ getNavItemUrl(ctaButton1) } type='solid' externalUrl={ ctaButton1?.link?.externalUrl }>
                  { ctaButton1.label }
                </Button>
              ) }

              { ctaButton2 && (
                <Button href={ getNavItemUrl(ctaButton2) } type='ghost' className='mix-blend-multiply' externalUrl={ ctaButton2?.link?.externalUrl }>
                  { ctaButton2.label }
                </Button>
              ) }
            </div>
          ) }
        </div>
        {
          products && products !== undefined &&
          <div className='col-span-12 md:col-span-8 md:col-start-3 flex md:justify-center mt-4'>
            {products.map((product, index) => {
              return (
                <div
                  key={ index }
                  className='py-1 px-2 border border-shade-200 bg-white rounded-2xl mr-2'
                >
                  { lookUp[product.productName] }
                </div>
              );
            })}
          </div>
        }
      </Grid>
    </>
  );
}
