import Grid from '@core/layout/Grid';
import { useEffect, useState } from 'react';

const lookUp = {
  BSO: 'Buitenschoolse opvang',
  POV: 'Peuteropvang',
  KDV: 'Kinderdagverblijf',
  ZJK: 'Zorg Jonge Kind',
};

const styling = {
  columnTitle: 'text-heading-xs md:text-heading-sm mb-2 md:mb-4',
  columnKey: 'text-body-md md:text-body-lg table-cell pb-2 h-8',
  columnValue: `
    text-body-md text-body-md--semibold md:text-body-lg md:text-body-lg--semibold table-cell pb-2
    xl:pl-8 md:pl-6 pl-4
  `,
};

export default function MultiContactSection(props) {
  const [ activeTab, setActiveTab ] = useState(props.products[0].productName);
  const [ product,  setProduct ] = useState(props.products[0]);

  useEffect(() => {
    setProduct(props.products.find((product) => product.productName == activeTab));
  }, [ activeTab, props.products ]);

  const renderTime = (day) => (
    <div className={ `${ styling.columnValue }` }>
      { day?.blockOne?.from && day.blockOne.from.length > 0 ? day.blockOne.from : '* ' } - { day?.blockOne?.to ?? '' }
      {
        day?.blockTwo?.to &&
        day?.blockTwo?.to.length > 0 &&
      ` / ${ day?.blockTwo?.from?.length > 0
        ? day.blockTwo?.from
        : '*' } - ${ day?.blockTwo?.to ?? '' }`
      }
    </div>
  );

  return (
    <Grid>
      <div className='col-span-12 flex mb-6 md:mb-8 xl:mb-12'>
        {
          props.products.map((product) => (
            <div
              key={ product._key }
              className={ sq`
                text-body-md text-body-md--semibold mr-8 hover:cursor-pointer py-4 first:pl-0 transition-all
                ${ activeTab === product.productName ? 'border-b border-primary text-primary' : '' }`
              }
              onClick={ () => setActiveTab(product.productName) }>
              { lookUp[product.productName] }
            </div>
          ))
        }
      </div>
      <div className='col-span-12'>
        <div className='mb-6 md:mb-8 xl:mb-12'>
          <strong>
          Alle GGD rapporten van deze locatie kun je makkelijk vinden door hieronder te klikken op de groene cijfers van het LRK-nummer. Je komt dan direct bij de rapporten van deze locatie uit!
          </strong>
        </div>
        <h2 className='text-heading-md md:text-heading-lg mb-6 md:mb-8 xl:12'>Contactgegevens</h2>
      </div>
      <div className='col-span-12 md:col-span-6 xl:col-span-4 mb-8 xl:mb-0'>
        <div className={ `${ styling.columnTitle }` }>Locatie</div>
        <div className='table self-start'>
          <div className='table-row-group'>
            <div className='table-row'>
              <div className={ `${ styling.columnKey } w-[163.5px] md:w-[116px] xl:w-[75px]` }>Naam</div>
              <div className={ `${ styling.columnValue }` }>{ product.contactDetails?.name }</div>
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Adres</div>
              <div className={ `${ styling.columnValue }` }>{ product.contactDetails?.address }</div>
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Postcode</div>
              <div className={ `${ styling.columnValue }` }>{ product.contactDetails?.zipCode }</div>
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Stad</div>
              <div className={ `${ styling.columnValue }` }>{ product.contactDetails?.city }</div>
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Postbus</div>
              <div className={ `${ styling.columnValue }` }>{ product.contactDetails?.postBox ?? '--' }</div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-span-12 md:col-span-6 xl:col-span-4  mb-8 xl:mb-0'>
        <div className={ `${ styling.columnTitle }` }>Contact</div>
        <div className='tatable self-start'>
          <div className='table-row-group'>
            <div className='table-row'>
              <div className={ `${ styling.columnKey } w-[163.5px] md:w-[145px]` }>Telefoon</div>
              <a href={ `tel: ${ product.contactDetails?.phoneNumber }` } className={ `${ styling.columnValue } text-primary-500` }>{ product.contactDetails?.phoneNumber }</a>
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Mailadres</div>
              <a href={ `mailto: ${ product.contactDetails?.email }` } className={ `${ styling.columnValue } text-primary-500` }>{ product.contactDetails?.email }</a>
            </div>
            {
              product.contactDetails?.lRKNumberKDV &&
              <div className='table-row' >
                <div className={ `${ styling.columnKey }` }>LRK-nummer KOV</div>
                <a
                  className={ `${ styling.columnValue } text-primary` }
                  href={ product.contactDetails?.lRKLink }
                  target='__blank'>
                  { product.contactDetails?.lRKNumberKDV }
                </a>
              </div>
            }
            {
              product.contactDetails?.lRKNumberGOB &&
              <div className='table-row' >
                <div className={ `${ styling.columnKey }` }>LRK-nummer GOB</div>
                <a
                  className={ `${ styling.columnValue } text-primary` }
                  href={ product.contactDetails?.lRKLink }
                  target='__blank'>
                  { product.contactDetails?.lRKNumberGOB }
                </a>
              </div>
            }
            {
              product.contactDetails?.lRKNumberBSO &&
              <div className='table-row'>
                <div className={ `${ styling.columnKey }` }>LRK-nummer BSO</div>
                <a
                  className={ `${ styling.columnValue } text-primary` }
                  href={ product.contactDetails?.lRKLink }
                  target='__blank'>
                  { product.contactDetails?.lRKNumberBSO }
                </a>
              </div>
            }
            {
              product.contactDetails?.lRKNumberPOV &&
              <div className='table-row'>
                <div className={ `${ styling.columnKey }` }>LRK-nummer POV</div>
                <a
                  className={ `${ styling.columnValue } text-primary` }
                  href={ product.contactDetails?.lRKLink }
                  target='__blank'>
                  { product.contactDetails?.lRKNumberPOV }
                </a>
              </div>
            }
            {
              product.contactDetails?.lRKNumberVGO &&
              <div className='table-row'>
                <div className={ `${ styling.columnKey }` }>LRK-nummer VGO</div>
                <a
                  className={ `${ styling.columnValue } text-primary` }
                  href={ product.contactDetails?.lRKLink }
                  target='__blank'>
                  { product.contactDetails?.lRKNumberVGO }
                </a>
              </div>
            }
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>KVK-nummer</div>
              <div className={ `${ styling.columnValue }` }>{ product.contactDetails?.kVKNumber ?? '--'}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-span-12 md:col-span-6 xl:col-span-4 mb-6 md:mb-8 xl:mb-12'>
        <div className={ `${ styling.columnTitle }` }>Openingstijden</div>
        <div className='table self-start'>
          <div className='table-row-group'>
            <div className='table-row'>
              <div className={ `${ styling.columnKey } xl:w-[75px] md:w-[116px] w-[163.5px]` }>Maandag</div>
              { renderTime(product.contactDetails?.weeklySchedule?.monday) }
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Dinsdag</div>
              { renderTime(product.contactDetails?.weeklySchedule?.tuesday) }
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Woensdag</div>
              { renderTime(product.contactDetails?.weeklySchedule?.wednesday) }
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Donderdag</div>
              { renderTime(product.contactDetails?.weeklySchedule?.thursday) }
            </div>
            <div className='table-row'>
              <div className={ `${ styling.columnKey }` }>Vrijdag</div>
              { renderTime(product.contactDetails?.weeklySchedule?.friday) }
            </div>
          </div>
        </div>
      </div>
      {
        product.productName === 'BSO' &&
        <div className='col-span-12 text-body-lg'>
          *  De openingstijd is afhankelijk van de sluitingstijden van de school.
        </div>
      }
    </Grid>
  );
}
