import React, { useEffect } from 'react';

export default function ScriptSection(props) {
  useEffect(() => {
    const contentDiv = document.getElementById('external-script');
    if (props.scriptSrc) {
      const newScript = document.createElement('script');
      newScript.src = props.scriptSrc;
      newScript.async = true;
      contentDiv?.appendChild(newScript);
    }

    return () => {
      const collection = document.getElementsByClassName('flatpickr-calendar');
      while(collection.length > 0){
        collection[0].parentNode.removeChild(collection[0]);
      }
      contentDiv.innerHTML = '';
    };
  }, [ props.scriptSrc ]);

  return (
    <div id='external-script' />
  );
}
