import React from 'react';
import Grid from '@components/core/layout/Grid';
import { Image } from '@components/core/Image';
import Button from '@components/core/Button';
import Icon from '@components/core/Icon';
import Link from 'next/link';
import { getInternalPath } from '@helpers/internalLink';

export default function DomainOverviewSection(props) {
  return (
    <div className='bg-inherit'>
      <Grid>
        <div className='col-start-3 col-span-8 text-center text-heading-md md:text-heading-lg md:mb-2 xl:mb-6'>{ props.title }</div>
        <div className={ sq`
          col-span-12
          md:col-start-1
          md:col-span-6
          xl:col-start-1
          xl:col-span-4
          rounded-[32px]
          overflow-hidden
          mt-6
          group
          hover:cursor-pointer
        ` }>
          <Link href={ props.kinderopvangLink.externalUrl ?? getInternalPath(props.kinderopvangLink.internalLink) }>
            <>
              <div className='relative aspect-[384/228]'>
                <Image
                  sanityImage={ props.kinderopvangImage.image }
                  layout='fill'
                  width={ 384 }
                  height={ 228 }
                  sizes='(min-width: 1024px) 384px, (min-width: 640px) 447px, 640px'
                />
              </div>
              <div className='flex justify-between items-center px-4 xl:px-6 py-4 bg-[#EDF3EA]'>
                <div className='text-heading-xs md:text-heading-sm leading-3'>
                  Kinderopvang
                </div>
                <Button type='icon' className='self-center' ariaLabel='Link naar kinderopvang'>
                  <Icon name={ 'ArrowForward' } />
                </Button>
              </div>
            </>
          </Link>
        </div>
        <div
          className={ sq`
            col-span-12
            md:col-start-7
            md:col-span-6
            xl:col-start-5
            xl:col-span-4
            rounded-[32px]
            overflow-hidden
            mt-6
            group
            hover:cursor-pointer
          ` }
        >
          <Link href={ props.jeugdzorgLink.externalUrl ?? getInternalPath(props.jeugdzorgLink.internalLink) }>
            <>
              <div className='relative aspect-[384/228]'>
                <Image
                  sanityImage={ props.jeugdzorgImage.image }
                  layout='fill'
                  width={ 384 } height={ 228 }
                  sizes='(min-width: 1024px) 216px, (min-width: 640px) 208px, 300px'
                />
              </div>
              <div className='flex justify-between items-center px-4 xl:px-6 py-4 bg-[#F1EDF5]'>
                <div className='text-heading-sm leading-3'>
                Zorg
                </div>
                <Button type='icon' className='self-center' ariaLabel='Link naar jeugdzorg'>
                  <Icon name={ 'ArrowForward' } />
                </Button>
              </div>
            </>
          </Link>
        </div>
        <div className={ sq`
          col-span-12
          md:col-start-1
          md:col-span-6
          xl:col-start-9
          xl:col-span-4
          rounded-[32px]
          overflow-hidden
          mt-6
          group
          hover:cursor-pointer
        ` }>
          <Link href={ props.internatenLink.externalUrl ?? getInternalPath(props.internatenLink.internalLink) }>
            <>
              <div className='relative aspect-[384/228]'>
                <Image
                  sanityImage={ props.internatenImage.image }
                  layout='fill'
                  width={ 384 }
                  height={ 228 }
                  sizes='(min-width: 1024px) 216px, (max-width: 1024px) and (min-width: 640px) 208px, (max-width: 640px) 300px'
                />
              </div>
              <div className='flex justify-between items-center px-4 xl:px-6 py-4 bg-[#E5F5FC]'>
                <div className='text-heading-sm leading-3'>
                  Meander Internaten
                </div>
                <Button type='icon' className='self-center' ariaLabel='Link naar Meander internaten'>
                  <Icon name={ 'ArrowForward' } />
                </Button>
              </div>
            </>
          </Link>
        </div>
      </Grid>
    </div>
  );
}
