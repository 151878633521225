//TODO: Add support for differnt input types
//TODO: Base on type we can automate certain validations no need to be given by parent.
// TODO: useRef instead of updating the parent, because value is already in the DOM

import { kebabCase, notEmpty } from '@helpers/string';
import { ChangeEvent, ChangeEventHandler, useEffect } from 'react';

const styling = {
  input: sq`
    w-full
    h-12
    px-6
    py-3
    border
    border-shade-100
    rounded-full
    text-body-lg
    outline-0

    hover:border-primary-300
    active:border-primary
    focus:border-primary

    placeholder-body-lg
    placeholder-shade-700
    disabled:border-shade-400
    disabled:bg-shade-50
  `,
  textarea: sq`
    w-full
    px-4
    py-3
    border
    border-shade-100
    rounded-[32px]
    text-body-lg
    outline-0

    hover:border-primary-300
    active:border-primary
    focus:border-primary

    placeholder-body-lg
    placeholder-shade-600
    disabled:border-shade-400
    disabled:bg-shade-50
  `,
  inputError: `
    border-error
  `,
  errorMessage: `
    block
    w-full
    text-body-sm
    mt-2
    text-error

    md:text-left
  `,
};

interface IInputProps {
  type?: 'text' | 'textarea'
  id?: string,
  name?: string,
  value: string,
  label?: string,
  required?: boolean,
  placeholder?: string
  disabled?: boolean,
  isValid?: boolean,
  validState?: { [key: string]: boolean },
  showErrorMessage?: boolean,
  onEnter?: () => void,

  emitOnChange?: (event: ChangeEvent) => void,
  emitSetErrorMessage?: (str: string) => void,

  validation?: { [key: string]: {
    validateFunction?: (value: any) => boolean ,
    errorMessage?: string,
  } },
  emitOnValidate?: (validateState: { [key: string]: boolean }) => void,

  customStyling?: {
    container?: {
      className?: string,
    },
    label?: {
      className?: string,
    },
    input?: {
      className?: string,
    },
    errorMessage?: {
      className?: string,
    },
  }
}

export default function Input({
  type = 'text',
  id,
  name,
  value,
  label,
  placeholder,
  required,
  disabled,
  isValid,
  validState,
  showErrorMessage,
  onEnter,
  emitOnChange,
  emitOnValidate,
  validation,
  customStyling,
}: IInputProps) {

  const inputId = id || (label && typeof label === 'string' ? kebabCase(label) : undefined);

  const runValidation = (value: any) => {
    const validityState: {[key: keyof typeof validation|string]: boolean} = {};

    if (
      (required && validation) || (value && value.length > 0 && validation) ) {
      for (const validateKey in validation) {
        if (validation[validateKey].validateFunction) {
          if (typeof validation[validateKey].validateFunction === 'function') {
            validityState[validateKey] = validation[validateKey].validateFunction?.(value) || false;
          } else {
            throw `Provided input validation with key '${ validateKey }' is not a function`;
          }
        }
      }
    }

    // If no 'required' validationFunction has been provided, perform our default one
    if (required &&  !validityState['required']) {
      validityState['required'] = notEmpty(value);
    }

    emitOnValidate && emitOnValidate(validityState);
  };

  const changeHandler: ChangeEventHandler = (e: ChangeEvent | any) => {
    if (emitOnChange) {
      emitOnChange(e);
    }

    const value = (e.target as HTMLInputElement).value;

    runValidation(value);
  };

  const renderErrorMessage = () => {
    for (const validateKey in validation) {
      if (validState && !validState[validateKey]) {
        return (
          <span className={ `${ styling.errorMessage } ${ customStyling?.errorMessage?.className ?? '' }` }>{ validation[validateKey].errorMessage }</span>
        );
      }
    }

    return null;
  };

  // Run once
  useEffect(() => {
    runValidation(value);
  }, []);

  const keyDownHandler = (e) => {
    if (e.key === 'Enter' && onEnter !== undefined) {
      onEnter();
    }
  };

  return (
    <div className={ `${ customStyling?.container?.className ?? '' }` }>
      { label &&
        <div className='flex items-center mb-1'>
          <label
            htmlFor={ inputId }
            className={ sq`
              text-body-md text-body-md--semibold
              ${ customStyling?.label?.className ?? '' }
            ` }>{ label }</label>
          {
            !required &&
            <div className='rounded ml-[6px] text-body-xs bg-primary-50 p-[2px]'>Optional</div>
          }
        </div>
      }
      {
        type === 'text' &&
        <input
          id={ inputId }
          value={ value }
          name={ name }
          onChange={ changeHandler }
          placeholder={ placeholder }
          disabled={ disabled }
          className={ `${ styling.input } ${ (showErrorMessage && !isValid) ? styling.inputError : null } ${ customStyling?.input?.className ?? '' }` }
          data-error={ showErrorMessage && !isValid }
          onKeyDown={ keyDownHandler }
        />
      }
      {
        type === 'textarea' &&
        <textarea
          id={ inputId }
          rows={ 4 }
          value={ value }
          onChange={ changeHandler }
          placeholder={ placeholder }
          disabled={ disabled }
          className={ `${ styling.textarea } ${ (showErrorMessage && !isValid) ? styling.inputError : null } ${ customStyling?.input?.className ?? '' }` }
          data-error={ showErrorMessage && !isValid }
        />
      }
      { !isValid && showErrorMessage && validState && (
        renderErrorMessage()
      ) }
    </div>
  );
}
